import React from 'react';

interface CustomTabsProps {
  children: React.ReactElement<CustomTabProps>[]; // Specify that children should be of type CustomTabProps
  selectedKey: string;
  onSelectionChange: (key: string) => void;
}

const CustomTabs: React.FC<CustomTabsProps> = ({
  children,
  selectedKey,
  onSelectionChange,
}) => {
  return (
    <div className="flex gap-2 px-4 py-2 border-b border-gray-300">
      {React.Children.map(children, (child) => {
        if (React.isValidElement(child)) {
          return React.cloneElement(child, {
            isSelected: child.props.id === selectedKey,
            onSelect: () => onSelectionChange(child.props.id),
          });
        }
        return null;
      })}
    </div>
  );
};

interface CustomTabProps {
  id: string;
  title: string;
  isSelected?: boolean;
  onSelect?: () => void;
}

const CustomTab: React.FC<CustomTabProps> = ({
  id,
  title,
  isSelected,
  onSelect,
}) => {
  return (
    <div
      className={`px-4 py-2 cursor-pointer ${isSelected ? 'border-b-2 border-primary text-primary' : ''}`}
      onClick={onSelect}
    >
      {title}
    </div>
  );
};

export { CustomTabs, CustomTab };
