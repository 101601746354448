import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface IMenuToggler {
  ToggleMenuItems: string;
}

const initialState: IMenuToggler = {
  ToggleMenuItems: window?.location?.pathname?.split('/')[1] || '',
};

const menuItemsSlice = createSlice({
  name: 'menuItems',
  initialState,
  reducers: {
    toggleMenu(state, action: PayloadAction<string>) {
      state.ToggleMenuItems = action.payload;
      return state;
    },
  },
});

export const { toggleMenu } = menuItemsSlice.actions;
export const selectMenuItems = (state: any) => state.menuItems;

export default menuItemsSlice.reducer;
