import React, { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AnimatePresence, motion } from 'framer-motion';
import { formatRelativeTime } from '../../utils/allConstants';
import { ReactComponent as KycIcon } from '../../SVGIcons/Notifications/kyc.svg';
import { selectNotification } from '../../store/notifications/notificationsSlice';
import { ReactComponent as SwapIcon } from '../../SVGIcons/Notifications/swap.svg';
import { ReactComponent as StackIcon } from '../../SVGIcons/Notifications/stake.svg';
import { ReactComponent as RewardIcon } from '../../SVGIcons/Notifications/reward.svg';
import { useNotificationsActions } from '../../store/notifications/notificationActions';
import { ReactComponent as ProfileIcon } from '../../SVGIcons/Notifications/profile.svg';
import { ReactComponent as DepositIcon } from '../../SVGIcons/Notifications/deposit.svg';
import { ReactComponent as WithdrawIcon } from '../../SVGIcons/Notifications/withdraw.svg';
import { ReactComponent as TransactionIcon } from '../../SVGIcons/Notifications/transaction.svg';
import { ReactComponent as ChangeEmailIcon } from '../../SVGIcons/Notifications/changeEmail.svg';
import { ReactComponent as CliamedRewardIcon } from '../../SVGIcons/Notifications/claimedReward.svg';
import { ReactComponent as RegisterationIcon } from '../../SVGIcons/Notifications/registeration.svg';
import { ReactComponent as ChangePasswordIcon } from '../../SVGIcons/Notifications/changePassword.svg';
import { ReactComponent as ProfilePictureIcon } from '../../SVGIcons/Notifications/profilePicture.svg';
import { ReactComponent as Notification } from '../../SVGIcons/Notifications/Notification.svg';
import { useNewsletterActions } from '../../store/newsLetter/newLetterActions';
import {
  selectNewsletter,
  setIsNewsletterOpen,
  setNewsletterModal,
  setNewsletterModalId,
} from '../../store/newsLetter/newsLetterSlice';
import { NewsLetterIcon } from '../../images/svgs';
import GlobalIconWrap from '../Global/GlobalIconWrap';
import GlobalModal from '../Global/GlobalModal';
import { INewsletterList } from '../../types/newsletter.types';

const transactionTypeList = ['deposit', 'swap', 'withdrawal', 'transfer'];
type NotificationComponent = React.ReactNode;

type Props = {
  activeTab?: string;
};

export const notificationCode: Record<any, NotificationComponent> = {
  transaction: <TransactionIcon />,
  withdrawal: <WithdrawIcon />,
  deposit: <DepositIcon />,
  transfer: <TransactionIcon />,
  swap: <SwapIcon />,
  stake: <StackIcon />,
  'claimed-reward': <CliamedRewardIcon />,
  reward: <RewardIcon />,
  'device-logged-in': <Notification />,
  registration: <RegisterationIcon />,
  'change-email': <ChangeEmailIcon />,
  'change-password': <ChangePasswordIcon />,
  'profile-image-upload': <ProfilePictureIcon />,
  'kyc-approved': <KycIcon />,
  'kyc-rejected': <KycIcon />,
  'profile-update': <ProfileIcon />,
};

const NewsLetterTabContent = ({ activeTab }: Props) => {
  const dispatch = useDispatch();

  const { readNewsletter, getAllNewsletter } = useNewsletterActions();
  const { newsletterList, allNewsletterList, unReadNewsletterCount } =
    useSelector(selectNewsletter);
  const [selectedNewsLetter, setSelectedNewsLetter] =
    useState<INewsletterList | null>(null);

  const onReadNewsletterHandler = async (id: string) => {
    await readNewsletter(id);
  };

  const filterNewsletter = useMemo(() => {
    // let filterlist: any = [];

    // if (activeTab === 'all') {
    //   filterlist = newsletterList;
    // } else {
    //   filterlist = allNewsletterList.filter(
    //     (dt: any) => dt.type === activeTab
    //   );
    // }
    return newsletterList;
  }, [newsletterList, unReadNewsletterCount]);

  return (
    <>
      <AnimatePresence>
        <motion.div
          initial={{ opacity: 0, x: -100 }}
          animate={{ opacity: 1, x: 0 }}
          exit={{ opacity: 0, x: -100 }}
          transition={{ duration: 0.3 }}
          className="flex flex-col"
          style={{ height: '100%', width: '100%' }}
        >
          <div className="w-full h-full">
            {filterNewsletter.length > 0 ? (
              filterNewsletter.map((itm: any, index: number) => {
                const read: boolean = itm.read;
                return (
                  <div className="" key={index}>
                    <div
                      className={`flex justify-between w-full gap-2 border-b border-borderLightGray p-4 cursor-pointer ${!read && 'bg-primary/10'}`}
                      onClick={() => {
                        setSelectedNewsLetter(itm);
                        if (!read) onReadNewsletterHandler(itm._id);
                      }}
                    >
                      <div className="w-10 h-10">
                        <div className="flex justify-center items-center bg-white rounded-full w-12 h-12 border border-gray-400">
                          <NewsLetterIcon />
                        </div>
                      </div>
                      <div className="flex flex-col justify-center gap-2 w-full">
                        <div className="w-full px-2">
                          {itm.newsDetails?.title}
                        </div>
                      </div>
                      <span className="flex flex-col gap-2 items-center text-xs text-primaryGrey  min-w-8">
                        {formatRelativeTime(itm.createdAt)}
                        {!read && (
                          <div className="w-2 h-2 bg-primaryRed rounded-full" />
                        )}
                      </span>
                    </div>
                  </div>
                );
              })
            ) : (
              <div className="w-full h-20 flex items-center justify-center">
                No Notifications Found!
              </div>
            )}
          </div>
        </motion.div>
      </AnimatePresence>
      <GlobalModal
        isOpen={selectedNewsLetter != null}
        onClose={() => setSelectedNewsLetter(null)}
        size="2xl"
        title={selectedNewsLetter?.newsDetails?.title}
      >
        <div
          dangerouslySetInnerHTML={{
            __html: selectedNewsLetter?.newsDetails?.body ?? '',
          }}
        ></div>
      </GlobalModal>
    </>
  );
  // switch (activeTab) {
  //   case 'all':

  //   case 'transaction':
  //     return (
  //       <AnimatePresence>
  //         <motion.div
  //           initial={{ opacity: 0, x: -100 }}
  //           animate={{ opacity: 1, x: 0 }}
  //           exit={{ opacity: 0, x: 100 }}
  //           transition={{ duration: 0.3 }}
  //           style={{ height: '100%', width: '100%' }}
  //         >
  //           <div className="w-full h-full">
  //             {newsletterList.filter((dt: any) =>
  //               transactionTypeList.includes(dt.type)
  //             ).length > 0 ? (
  //               newsletterList
  //                 .filter((dt: any) => transactionTypeList.includes(dt.type))
  //                 ?.map((itm: any, index: number) => {
  //                   return (
  //                     <div className="" key={index}>
  //                       {!itm.read ? (
  //                         <div
  //                           className="flex justify-between w-full gap-2 border-b border-borderLightGray p-4 bg-primary/10 cursor-pointer"
  //                           onClick={() => {
  //                             onReadNewsletterHandler(itm._id);
  //                           }}
  //                         >
  //                           <div className="w-10 h-10">
  //                             {notificationCode[itm?.type] ?? <ProfileIcon />}{' '}
  //                           </div>
  //                           <div className="flex flex-col justify-center gap-2 w-full">
  //                             <div className="w-full px-2">{itm.message}</div>
  //                           </div>
  //                           <span className="flex flex-col gap-2 items-center text-xs text-primaryGrey  min-w-8">
  //                             {formatRelativeTime(itm.createdAt)}
  //                             <div className="w-2 h-2 bg-primaryRed rounded-full" />
  //                           </span>
  //                         </div>
  //                       ) : (
  //                         <div className="flex justify-between w-full gap-2 border-b border-borderLightGray p-4 ">
  //                           <div className="w-10 h-10">
  //                             {notificationCode[itm?.type] ?? <ProfileIcon />}{' '}
  //                           </div>
  //                           <div className="flex flex-col justify-center gap-2 w-full">
  //                             <div className="w-full px-2">{itm.message}</div>
  //                           </div>
  //                           <span className="text-xs text-primaryGrey min-w-6">
  //                             {formatRelativeTime(itm.createdAt)}
  //                           </span>
  //                         </div>
  //                       )}
  //                     </div>
  //                   );
  //                 })
  //             ) : (
  //               <div className="w-full h-20 flex items-center justify-center">
  //                 No Newsletter Found!
  //               </div>
  //             )}
  //           </div>
  //         </motion.div>
  //       </AnimatePresence>
  //     );
  //   case 'coming-soon':
  //     return (
  //       <AnimatePresence>
  //         <motion.div
  //           initial={{ opacity: 0, x: -100 }}
  //           animate={{ opacity: 1, x: 0 }}
  //           exit={{ opacity: 0, x: 100 }}
  //           transition={{ duration: 0.3 }}
  //           style={{ height: '100%', width: '100%' }}
  //         >
  //           {' '}
  //           <div className="w-full h-full">
  //             {filterNewsletter.length > 0 ? (
  //               filterNewsletter.map((itm: any, index: number) => {
  //                 return (
  //                   <div>
  //                     {!itm.read ? (
  //                       <div
  //                         className="flex justify-between w-full gap-2 border-b border-borderLightGray p-4 bg-primary/10"
  //                         onClick={() => {
  //                           onReadNewsletterHandler(itm._id);
  //                         }}
  //                       >
  //                         <div className="w-10 h-10">
  //                           {notificationCode[itm?.type] ?? <ProfileIcon />}{' '}
  //                         </div>
  //                         <div className="flex flex-col justify-center gap-2 w-full">
  //                           <div className="w-full px-2">{itm.message}</div>
  //                         </div>
  //                         <span className="flex flex-col gap-2 items-center text-xs text-primaryGrey  min-w-8">
  //                           {formatRelativeTime(itm.createdAt)}
  //                           <div className="w-2 h-2 bg-primaryRed rounded-full" />
  //                         </span>
  //                       </div>
  //                     ) : (
  //                       <div className="flex justify-between w-full gap-2 border-b border-borderLightGray p-4 ">
  //                         <div className="w-10 h-10">
  //                           {notificationCode[itm?.type] ?? <ProfileIcon />}{' '}
  //                         </div>
  //                         <div className="flex flex-col justify-center gap-2 w-full">
  //                           <div className="w-full px-2">{itm.message}</div>
  //                         </div>
  //                         <span className="text-xs text-primaryGrey min-w-6">
  //                           {formatRelativeTime(itm.createdAt)}
  //                         </span>
  //                       </div>
  //                     )}
  //                   </div>
  //                 );
  //               })
  //             ) : (
  //               <div className="w-full h-20 flex items-center justify-center">
  //                 No Notifications Found!
  //               </div>
  //             )}
  //           </div>
  //         </motion.div>
  //       </AnimatePresence>
  //     );
  //   default:
  //     return null;
  // }
};

export default NewsLetterTabContent;
