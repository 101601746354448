type Props = {};

const RenderRewardsMessage = (props: Props) => {
  return (
    <div className="col-span-2 w-full flex items-center justify-between flex-col md:flex-row space-y-2 md:space-y-0">
      <div className="md:flex md:items-center md:space-x-2.5 text-gray-400 text-xs">
        * Rewards shown are indicative and not guaranteed. Actual minting
        rewards may vary based on market conditions and participation. Rewards
        depends on LYK price and auto-link status. Conduct your own research
        before making any decisions.
      </div>
    </div>
  );
};

export default RenderRewardsMessage;
