import { toggleDrawer } from '../store/drawer/drawerSlice';
import { useWindowSize } from '../utils/helper';
import { useDispatch } from 'react-redux';

function useSideBarToggler() {
  const size = useWindowSize();
  const dispatch = useDispatch();

  const sideBarMenuToggler = () => {
    if (size.width > 0) {
      if (size.width <= 768) {
        dispatch(toggleDrawer(false));
        localStorage.setItem('drawer', JSON.stringify(false));
      } else {
        dispatch(toggleDrawer(true));
        localStorage.setItem('drawer', JSON.stringify(true));
      }
    }
  };

  return {
    sideBarMenuToggler,
  };
}

export default useSideBarToggler;
