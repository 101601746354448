//REDUX-TOOLKIT
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TOKEN_ROUND_ICONS } from '../../utils/allConstants';

enum WITHDRAW_TABS {
  EXTERNAL = 'External Address',
  INTERNAL = 'Homnifi User ID',
}

export const withdraw_tabs = {
  [WITHDRAW_TABS.EXTERNAL]: 'External Address',
  [WITHDRAW_TABS.INTERNAL]: 'Homnifi User ID',
};

export const tabsOptions = [
  withdraw_tabs[WITHDRAW_TABS.EXTERNAL],
  withdraw_tabs[WITHDRAW_TABS.INTERNAL],
];

export interface StateWithraw {
  walletList: TOKEN[];
  selectedWallet: any | null;
  externalAddress: string;
  homnifiUserId: string;
  withdrawType: WITHDRAW_TABS.EXTERNAL;
  networkList: any[];
  selectedNetwork: string | null;
  withdrawAmount: number;
  withdrawAmountPercentage: number;
  usdtOptions: any[];
  remarks: boolean;
  additionalRemarks: string;
  currentStep: 1 | 2 | 3 | 4;
  confirmation: boolean;
  isErrorAddress: boolean;
  userSelected: boolean;
  addressListSlyk: any;
  tabsChangeFlag: boolean;
  homnifiUser: any;
  addressApiResponse: boolean;
}

export interface TOKEN {
  name: string;
  value: string;
  icon: any;
}

export const TOKEN_LYK_D: TOKEN = {
  name: 'LYK - D',
  value: 'LYK - D',
  icon: TOKEN_ROUND_ICONS['USDT'],
};

export const WITHDRAW_SLICE_NAME = 'withdraw';
const initialState: StateWithraw = {
  walletList: [],
  selectedWallet: null,
  externalAddress: '',
  homnifiUserId: '',
  withdrawType: WITHDRAW_TABS.EXTERNAL,
  networkList: [],
  selectedNetwork: null,
  withdrawAmount: 0.0,
  withdrawAmountPercentage: 0,
  usdtOptions: [
    {
      address: 'x3m173ndfk288162',
      network: {
        type: 'ndhwquekk2c93c91lsa',
      },
    },
  ],
  remarks: false,
  additionalRemarks: '',
  currentStep: 1,
  confirmation: false,
  isErrorAddress: false,
  userSelected: false,
  addressListSlyk: null,
  tabsChangeFlag: false,
  homnifiUser: false,
  addressApiResponse : false,
};

const slice = createSlice({
  name: WITHDRAW_SLICE_NAME,
  initialState,
  reducers: {
    setWithdrawSlice: (state, action: PayloadAction<any>) => {
      return { ...state, ...action.payload };
    },
  },
});

export const withdrawSliceAction = slice.actions;

export default slice.reducer;
