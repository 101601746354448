import { ResponsivePie } from '@nivo/pie';
import React from 'react';

export type TPieChart = {
  data?: any;
  colors?: any;
  valueFormat?: any;
  className?: string;
  innerRadius?: number;
  enableArcLabels?: boolean;
  enableCustomLegend?: boolean;
  enableArcLinkLabels?: boolean;
  RenderBottom?: React.ReactNode;
};

const CustomLegend = ({ data }: { data: any }) => (
  <div className="flex flex-row sm:flex-col md:flex-row xl:flex-col justify-start gap-4">
    {data.map((item: any) => (
      <div key={item.id} className="flex items-start gap-2 rounded-full">
        <svg
          width="11"
          height="11"
          viewBox="0 0 11 11"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="5.20996" cy="5.55859" r="5" fill={item.color} />
        </svg>

        <div className="text-sm text-[#7D7D7D7D] flex flex-col items-start justify-start gap-2">
          <span>{item.label}</span>{' '}
          <span className="text-sm font-medium" style={{ color: item.color }}>
            ${item.value.toLocaleString()}
          </span>
        </div>
      </div>
    ))}
  </div>
);

const CenteredMetric = ({
  centerX,
  centerY,
  data,
}: {
  centerX: number;
  centerY: number;
  data: any;
}) => {
  const total = data.reduce((sum: number, item: any) => {
    if (item.id === 'staked-amount' || item.id === 'total-profit') {
      return sum + item.value;
    }
    return sum;
  }, 0);

  return (
    <g transform={`translate(${centerX}, ${centerY})`}>
      <text
        textAnchor="middle"
        dominantBaseline="central"
        style={{ fontSize: '16px', fontWeight: '600' }}
        className="text-black"
      >
        ${total?.toFixed(2)}
      </text>
    </g>
  );
};

const PieChart = ({
  data,
  colors,
  valueFormat,
  className,
  innerRadius,
  enableArcLabels,
  enableCustomLegend,
  RenderBottom,
  enableArcLinkLabels,
}: TPieChart) => {
  return (
    <section className={`flex flex-col items-center gap-5 w-full ${className}`}>
      <article
        className={`flex flex-col sm:flex-row md:flex-col xl:flex-row items-center justify-center gap-2 w-full h-full`}
      >
        <div className="w-[18rem] h-[15rem] justify-center items-center content-center">
          <ResponsivePie
            data={data}
            colors={colors}
            valueFormat={valueFormat}
            innerRadius={innerRadius || 0.5}
            padAngle={2}
            cornerRadius={3}
            activeOuterRadiusOffset={3}
            borderWidth={1}
            borderColor={{
              from: 'color',
              modifiers: [['darker', 0.2]],
            }}
            enableArcLabels={enableArcLabels}
            arcLinkLabelsSkipAngle={10}
            arcLinkLabelsTextColor="#333333"
            arcLinkLabelsThickness={2}
            arcLinkLabelsColor={{ from: 'color' }}
            arcLabelsSkipAngle={10}
            arcLabelsTextColor={{
              from: 'color',
              modifiers: [['darker', 2]],
            }}
            margin={{ top: 20, right: 20, bottom: 20, left: 20 }}
            startAngle={0.1}
            enableArcLinkLabels={enableArcLinkLabels}
            arcLinkLabelsOffset={-14}
            defs={[
              {
                id: 'dots',
                type: 'patternDots',
                background: 'inherit',
                color: 'rgba(255, 255, 255, 0)',
                size: 4,
                padding: 1,
                stagger: true,
              },
              {
                id: 'lines',
                type: 'patternLines',
                background: 'inherit',
                color: 'rgba(255, 255, 255, 0.3)',
                rotation: -45,
                lineWidth: 6,
                spacing: 10,
              },
            ]}
            fill={[
              {
                match: { id: 'ruby' },
                id: 'dots',
              },
              {
                match: { id: 'c' },
                id: 'dots',
              },
              {
                match: { id: 'go' },
                id: 'dots',
              },
            ]}
            layers={[
              'arcs',
              'arcLabels',
              'arcLinkLabels',
              'legends',
              ({ centerX, centerY }) => (
                <CenteredMetric
                  centerX={centerX}
                  centerY={centerY}
                  data={data}
                />
              ),
            ]}
          />
        </div>

        {enableCustomLegend ? (
          <CustomLegend
            data={data.map((item: any) => ({
              ...item,
              color: item.color || '#000', // Assign colors if not specified
            }))}
          />
        ) : (
          ''
        )}
      </article>

      {RenderBottom && RenderBottom}
    </section>
  );
};

export default PieChart;
