import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store';
import { hideModal } from '../../store/modal/modalSlice';
import { logout } from '../../utils/network/ApiRequestBID';
import GlobalModal from '../Global/GlobalModal';
import GlobalButton from '../Global/GlobalButton';


const LogoutModal: React.FC = () => {
    const dispatch = useDispatch();
    const isVisible = useSelector((state: RootState) => state.modal.isVisible);

    const handleClose = () => {
        dispatch(hideModal());
    };

    const handleConfirm = () => {
        handleClose();
        logout();
    };

    if (!isVisible) return null;

    return (

        <GlobalModal
            size="sm"
            isOpen={isVisible}
            onClose={() => { handleClose() }}
            hideCloseButton={true}
            noHeader={true}
            isDismissable={false}

        >
            <div className="flex flex-col gap-8 font-light text-sm text-primaryGrey p-4">
                <div className=" font-medium text-sm text-defaultBlack">
                    Your session has been expired. Please login again!
                </div>

                <div className="flex items-center gap-4">
                    <GlobalButton
                        text="Logout"
                        type="button"
                        onClick={() => {
                            handleConfirm()
                        }}
                        classNames="!text-sm"
                    />
                </div>
            </div>
        </GlobalModal>

    );
};

export default LogoutModal;