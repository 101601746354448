import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import io, { Socket } from 'socket.io-client';
import {
  selectSocketIO,
  setConnectionStatus,
  setDisconnectReason,
  setError,
  setIsConnected,
  setIsConnecting,
  setIsDisconnected,
  setSocket,
} from '../../store/socketIO/socketIOSlice';
import { SocketIOSConnectionStatus } from '../../types/socketIO.types';

const useSocketIO = (url: string, options?: Record<string, any>) => {
  const dispatch = useDispatch();
  const { socket } = useSelector(selectSocketIO);

  const establishSocketConnection = useCallback(() => {
    const socketInstance: Socket = io(url, {
      ...options,
      reconnection: true,
      reconnectionDelay: 1000,
      reconnectionDelayMax: 5000,
      reconnectionAttempts: options?.reconnectionRetries || 5,
    });

    socketInstance.on('connect', () => {
      console.info('Connected with socket.io');

      dispatch(setSocket(socketInstance));
      dispatch(setIsConnected(true));
      dispatch(setIsDisconnected(false));
      dispatch(setIsConnecting(false));
      dispatch(setConnectionStatus(SocketIOSConnectionStatus.CONNECTED));
    });

    socketInstance.on('disconnect', (reason) => {
      console.info('Disconnecting from socket.io');

      dispatch(setIsConnected(false));
      dispatch(setIsDisconnected(true));
      dispatch(setIsConnecting(false));
      dispatch(setConnectionStatus(SocketIOSConnectionStatus.DISCONNECTED));
      dispatch(setDisconnectReason(reason));
    });

    socketInstance.on('connect_error', (error) => {
      console.info('Connection Error with socket.io');

      dispatch(setIsConnected(false));
      dispatch(setIsDisconnected(false));
      dispatch(setIsConnecting(true));
      dispatch(setConnectionStatus(SocketIOSConnectionStatus.ERROR));
      dispatch(setError(error.message));
    });

    return () => {
      socketInstance.disconnect();
    };
  }, [url, options]);

  useEffect(() => {
    return () => {
      socket?.off('connect');
      socket?.off('connect_error');
      socket?.off('disconnect');
    };
  }, [socket]);

  return { establishSocketConnection };
};

export default useSocketIO;
