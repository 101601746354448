import * as React from 'react'
import GlobalHeader from '../../components/Global/GlobalHeader'
import { homnifiLogo } from '../../images'

const Community = () => {
    return (
        <section className="flex flex-col gap-4 w-full">

            <GlobalHeader title="MY COMMUNITY" />

            <div className="w-full h-96 flex flex-col gap-6 items-center justify-center bg-white border border-borderBlue rounded-lg p-4">
                <img src={homnifiLogo} alt='HomnifiLogo' className='w-32' />
                <span className='font-medium uppercase text-4xl text-primary text-center'>Coming Soon</span>
                <div className='text-primaryGrey text-sm text-center'>This section is coming soon. Stay Tuned with <span className='text-primary'>Homnifi</span></div>
            </div>
        </section>
    )
}

export default Community