//REDUX-TOOLKIT
import {
  setBadgeUpdated,
  setUserActivity,
  setBadge,
  removeNotification,
  setInfinitLoader,
  setHomnifiNotificationList,
  updateNotificationStatus,
  setMarkAllAsRead,
  setHomniFiAllNotificationList,
} from './notificationsSlice';
import { useDispatch } from 'react-redux';
//UTILS
import {
  DELETE_NOTIFICATIONS,
  GET_HOMNIFI_NOTIFICATIONS,
  GET_USER_ACTIVITY,
  NOTIFICATIONS_BADGE,
  READ_ALL_NOTIFICATIONS,
  READ_NOTIFICATIONS,
  READ_HOMNIFI_NOTIFICATION,
  ALL_READ_HOMNIFI_NOTIFICATIONS,
  CREATE_HOMNIFI_NOTIFICATIONS,
} from '../../utils/network/ApiEndpoints';
import ApiRequestHomnifi from '../../utils/network/ApiRequestHomnifi';

export const useNotificationsActions = () => {
  const dispatch = useDispatch();

  const getUserActivity = async (page = 1, force = false) => {
    const url = GET_USER_ACTIVITY + '?page=' + page;

    return await ApiRequestHomnifi()
      .request({
        method: 'GET',
        url: url,
      })
      .then((response: any) => {
        dispatch(
          setUserActivity({
            activityData: response.data.data,
            page,
            force,
          })
        );
        return response.data.data;
      })
      .catch((error: any) => error);
  };

  const readNotification = async () => {
    const url = READ_NOTIFICATIONS;

    return await ApiRequestHomnifi()
      .request({
        method: 'PUT',
        url: url,
      })
      .then((response: any) => {
        dispatch(setBadgeUpdated());
        return response;
      })
      .catch((error: any) => error);
  };

  const deleteNotification = async (notification_id: string) => {
    const url = DELETE_NOTIFICATIONS + notification_id;

    return await ApiRequestHomnifi()
      .request({
        method: 'POST',
        url: url,
      })
      .then((response: any) => {
        dispatch(removeNotification(notification_id));
        return response;
      })
      .catch((error: any) => error);
  };

  const readAllNotifications = async () => {
    return await ApiRequestHomnifi()
      .request({
        method: 'POST',
        url: READ_ALL_NOTIFICATIONS,
      })
      .then((response: any) => {
        return response;
      })
      .catch((error: any) => error);
  };

  const getBadge = async () => {
    return await ApiRequestHomnifi()
      .request({
        method: 'GET',
        url: NOTIFICATIONS_BADGE,
      })
      .then((response: any) => {
        dispatch(setBadge(response.data.data.badge));
        return response;
      })
      .catch((error: any) => error);
  };
  const getHomnifiNotification = async (
    page: number = 1,
    limit: number = 10
  ) => {
    dispatch(setInfinitLoader(true));
    return await ApiRequestHomnifi()
      .request({
        method: 'GET',
        url: `${GET_HOMNIFI_NOTIFICATIONS}?page=${page}&limit=${limit}`,
      })
      .then((response: any) => {
        dispatch(setHomnifiNotificationList(response.data.data));
        dispatch(setInfinitLoader(false));
        return response;
      })
      .catch((error: any) => {
        dispatch(setInfinitLoader(false));
        return error;
      });
  };

  const getHomnifiAllNotification = async () => {
    dispatch(setInfinitLoader(true));
    return await ApiRequestHomnifi()
      .request({
        method: 'GET',
        url: `${GET_HOMNIFI_NOTIFICATIONS}`,
        params: {
          page: 1,
          limit: 100,
        },
      })
      .then((response: any) => {
        dispatch(setHomniFiAllNotificationList(response.data.data?.list));
        dispatch(setInfinitLoader(false));
        return response;
      })
      .catch((error: any) => {
        dispatch(setInfinitLoader(false));
        return error;
      });
  };

  const readHomnifiNotifications = async (id: string, page: number = 1) => {
    return await ApiRequestHomnifi()
      .request({
        method: 'POST',
        url: `${READ_HOMNIFI_NOTIFICATION}/${id}`,
      })
      .then((response: any) => {
        if (response.data.status === 201 || response.data.status === true) {
          dispatch(updateNotificationStatus(id));
        }
        // getHomnifiAllNotification();
        // getHomnifiNotification();
        return response;
      })
      .catch((error: any) => error);
  };

  const readAllHomnifiNotifications = async () => {
    return await ApiRequestHomnifi()
      .request({
        method: 'POST',
        url: ALL_READ_HOMNIFI_NOTIFICATIONS,
      })
      .then((response: any) => {
        console.log('Read all notification response::', response.data);
        dispatch(setMarkAllAsRead(0));
        // getHomnifiNotification();
        return response;
      })
      .catch((error: any) => error);
  };

  const createNotification = async (data: any) => {
    return await ApiRequestHomnifi()
      .request({
        method: 'POST',
        url: CREATE_HOMNIFI_NOTIFICATIONS,
        data,
      })
      .then((response: any) => {
        getHomnifiNotification();
        return response;
      })
      .catch((error: any) => error);
  };

  return {
    getUserActivity,
    readNotification,
    deleteNotification,
    readAllNotifications,
    getBadge,
    getHomnifiNotification,
    readHomnifiNotifications,
    readAllHomnifiNotifications,
    createNotification,
    getHomnifiAllNotification,
  };
};
