import { useEffect } from 'react';
import Navbar from './common/Navbar';
import Sidebar from './common/Sidebar';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import BottomBar from './common/BottomBar';
import { useDispatch, useSelector } from 'react-redux';
import { selectDrawer } from '../store/drawer/drawerSlice';
import PrelineInit from './PrelineInit';
import useUserCheck from '../hooks/useUserCheck';
import { checkPercentage } from '../store/user/userSlice';
import { useGeneralActions } from '../store/general/generalActions';
import { PAGES } from '../utils/routers';
import { useUserActions } from '../store/user/userActions';
import useSocketIO from '../hooks/socketIO/useSocketIO';
import { PLATFORM_NAME, SOCKET_BASE_URL } from '../utils/constants';
import { selectSocketIO } from '../store/socketIO/socketIOSlice';
import { selectNewsletterModal, selectNewsletterModalId, setNewsletterModal } from '../store/newsLetter/newsLetterSlice';
import GlobalModal from './Global/GlobalModal';
import RenderInstructionModal from '../pages/Dashboard/_components/RenderInstructionModal';
import RenderModalContent from '../pages/Dashboard/_components/RenderModalContent';
import ScrollToTop from '../utils/scrollToTop';


export default function Layout() {
  const { user } = useUserCheck();

  if (!user) {
    return <Navigate to={PAGES.LOGIN} replace />;
  }

  return <MainLayout />;
}

const MainLayout = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const toggleDrawer = useSelector(selectDrawer);
  const { socket } = useSelector(selectSocketIO);
  const { user, checkLayoutValidation, fetchUser } = useUserCheck();
  const { GetConvertedPrice } = useGeneralActions();
  const { updateUserData } = useUserActions();
  const isNewsletterModal = useSelector(selectNewsletterModal);
  const isNewsletterModalId = useSelector(selectNewsletterModalId);


  const { establishSocketConnection } = useSocketIO(SOCKET_BASE_URL);
  useEffect(() => {
    if (socket) return;
    establishSocketConnection();
  }, [socket]);

  useEffect(() => {
    checkLayoutValidation();
  }, [user]);

  useEffect(() => {
    fetchUser();
  }, []);

  useEffect(() => {
    if (user) {
      dispatch(checkPercentage());
      GetConvertedPrice();

      if (
        !localStorage.getItem('homnifi-username') ||
        !localStorage.getItem('homnifi-dateJoined')
      ) {
        updateUserData(user);
      }
    }
  }, [user]);


  return (
    <PrelineInit>
      <section className="relative">
        <Navbar drawer={toggleDrawer} />
        <div className="flex justify-center w-full">
          <Sidebar drawer={toggleDrawer} />
          <div
            className={`min-h-screen w-full overflow-hidden ${location.pathname === '/' ? 'bg-white' : 'px-4 md:px-12 sm:px-8 md:pt-10 md:pb-10 pt-8 pb-36 bg-pageBackground'}`}
          >
            <ScrollToTop />

            <Outlet />
          </div>
        </div>
        <BottomBar />
        <GlobalModal
          isOpen={isNewsletterModal}
          onClose={() => {
            // dispatch(setLoginPopup(false));
            dispatch(setNewsletterModal(false))
            // setIsLogInPopClosed(prev => !prev)
          }}
          title={`Announcement: ${PLATFORM_NAME} Platform is Now Live!`}
          childrenClass="p-0"
          size="2xl"
        >
          {/* {isNewsletterModalId === "1" ? <RenderInstructionModal /> : <RenderModalContent />} */}
        </GlobalModal>
      </section>
    </PrelineInit>
  );
};
