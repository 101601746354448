//REDUX-TOOLKIT
import {
  setNewsletterModal,
  setNewsletterData,
  setBadge,
  removeNewsletter,
  setUserActivity,
  setBadgeUpdated,
  updateNewsletterStatus,
  setMarkAllAsRead,
  setIsNewsletterOpen,
  setAllNewsletterList,
  setNewNewsletter,
  removeNewNewsletter,
  setIsLoading,
  setNewsletterList,
} from './newsLetterSlice';
import { useDispatch } from 'react-redux';
//UTILS
import {
  GET_USER_ACTIVITY,
  GET_NEWS_LETTER,
  READ_NEWS_LETTER,
  CREATE_NEWS_LETTER,
} from '../../utils/network/ApiEndpoints';
import ApiRequestHomnifi from '../../utils/network/ApiRequestHomnifi';
import { PLATFORM_NAME } from '../../utils/constants';

export const useNewsletterActions = () => {
  const dispatch = useDispatch();

  const getUserActivity = async (page = 1, force = false) => {
    const url = GET_USER_ACTIVITY + '?page=' + page;

    return await ApiRequestHomnifi()
      .request({
        method: 'GET',
        url: url,
      })
      .then((response: any) => {
        dispatch(
          setUserActivity({
            activityData: response.data.data,
            page,
            force,
          })
        );
        return response.data.data;
      })
      .catch((error: any) => error);
  };


  const deleteNewsletter = async (notification_id: string) => {
    //   const url = DELETE_NOTIFICATIONS + notification_id;
    //   return await ApiRequestHomnifi()
    //     .request({
    //       method: 'POST',
    //       url: url,
    //     })
    //     .then((response: any) => {
    //       dispatch(removeNotification(notification_id));
    //       return response;
    //     })
    //     .catch((error: any) => error);
  };

  // const readAllNewsletter = async () => {
  //   return await ApiRequestHomnifi()
  //     .request({
  //       method: 'POST',
  //       url: READ_ALL_NOTIFICATIONS,
  //     })
  //     .then((response: any) => {
  //       return response;
  //     })
  //     .catch((error: any) => error);
  // };

  const getBadge = async () => {
    // return await ApiRequestHomnifi()
    //   .request({
    //     method: 'GET',
    //     url: NOTIFICATIONS_BADGE,
    //   })
    //   .then((response: any) => {
    //     dispatch(setBadge(response.data.data.badge));
    //     return response;
    //   })
    //   .catch((error: any) => error);
  };
  const getNewsletter = async (page: number = 1, limit: number = 10) => {
    
    dispatch(setIsLoading(true));
    return await ApiRequestHomnifi()
      .request({
        method: 'GET',
        url: `${GET_NEWS_LETTER}?page=${page}&limit=${limit}`,
      })
      .then((response: any) => {
        const {data} = response;
        
        dispatch(setNewsletterList(data.data));
        return response;
      })
      .catch((error: any) => {
        return error;
      })
      .finally(() => {
        dispatch(setIsLoading(false));
      });

  };

  const getAllNewsletter = async () => {
    dispatch(setIsLoading(false));
      return await ApiRequestHomnifi()
        .request({
          method: 'GET',
          url: `${GET_NEWS_LETTER}`,
          params: {
            page: 1,
            limit: 100,
          },
        })
        .then((response: any) => {
          const {data} = response;
          dispatch(setAllNewsletterList(data.data?.list));
          return response;
        })
        .catch((error: any) => {
          return error;
        })
        .finally(()=>{
          dispatch(setIsLoading(false));
        })
  };

  const readNewsletter = async (id: string, page: number = 1) => {
      return await ApiRequestHomnifi()
        .request({
          method: 'POST',
          url: `${READ_NEWS_LETTER}/${id}`,
        })
        .then((response: any) => {
          if (response.data.status === 201 || response.data.status === true) {
            dispatch(updateNewsletterStatus(id));
          }
          return response;
        })
        .catch((error: any) => error);
  };

  const readAllNewsletter = async () => {
      return await ApiRequestHomnifi()
        .request({
          method: 'POST',
          url: READ_NEWS_LETTER,
        })
        .then((response: any) => {
          dispatch(setMarkAllAsRead(0));
          // getHomnifiNotification();
          return response;
        })
        .catch((error: any) => error);
  };

  const createNewsletter = async (data: any) => {
      return await ApiRequestHomnifi()
        .request({
          method: 'POST',
          url: CREATE_NEWS_LETTER,
          data,
        })
        .then((response: any) => {
          getNewsletter();
          return response;
        })
        .catch((error: any) => error);
  };

  return {
    getNewsletter,
    getAllNewsletter,
    createNewsletter,
    deleteNewsletter,
    readNewsletter,
    readAllNewsletter,
    getBadge,
    getUserActivity,
  };
};
