import React, { useEffect } from 'react';
import Slider from 'react-slick';
import AuthSlider from './AuthSlider';
import { LayoutImage1, LayoutImage2, LayoutImage3 } from '../../images/Auth';
import { useSelector } from 'react-redux';
import { selectDrawerSlice } from '../../store/drawer/drawerSlice';
import { sliderData } from '../../data/authSlider';

type Props = {};
const settings = {
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  arrows: false,
  dots: true,
  autoplay: true,
  autoplaydelay: 10000,
  autoplayspeed: 10000,
  afterChange: (change: any) => {},
  customPaging: (i: any) => (
    <button key={i} data-key={i} name={'my_custom_' + i}></button>
  ),
  appendDots: (dots: any) => (
    <ul style={{ margin: '0px' }} onClick={(dot: any) => {}}>
      {dots}
    </ul>
  ),
};
// const backgroundImages = [
//   LayoutImage1,
//   LayoutImage2,
//   LayoutImage3,
//   LayoutImage2,
// ];

const AuthSliderWrapper = (props: Props) => {
  const { activeSlider } = useSelector(selectDrawerSlice);
  let sliderRef: any = React.useRef<any>(null);

  useEffect(() => {
    if (sliderRef.current) {
      sliderRef.current.slickGoTo(activeSlider);
    }
  }, [activeSlider, sliderRef.current]);

  return (
    <div className="hidden items-center justify-center w-[40%]  md:flex">
      <div className="relative w-full">
        <div
          className="absolute -top-8 -left-8 w-52 h-80 p-1 bg-gradient-to-r from-primary to-primaryLight rounded-tl-[4rem] rounded-br-[4rem] overflow-hidden"
          style={{ zIndex: 0 }}
        >
          <div className="bg-white w-full h-full rounded-tl-[3.8rem] rounded-br-[3.8rem]" />
        </div>

        <div
          className="relative max-w-96 lg:h-[40rem] h-[32rem] w-full overflow-hidden rounded-tl-[4rem] rounded-br-[4rem]"
          style={{ zIndex: 99 }}
        >
          <div className="relative">
            <Slider
              {...settings}
              ref={sliderRef}
              className="w-full authSlider "
            >
              {sliderData.map((slider, index) => (
                <div key={index}>
                  <div
                    className={`bg-cover bg-no-repeat bg-center w-full lg:h-[42rem] h-[32rem]`}
                    style={{
                      backgroundImage: `url(${slider.image})`,
                    }}
                  >
                    <div className="w-full h-full bg-gradient-to-b from-transparent via-transparent to-primaryDark/60 relative bottom-14 select-none cursor-pointer">
                      <div className="w-full flex h-full items-end justify-center text-white">
                        <div className="px-5">
                          <div className="flex flex-col gap-2 bg-white/10 backdrop-blur-md p-2 w-full text-center rounded-lg">
                            <div className="font-baumans text-lg">
                              {slider.title}
                            </div>
                            <span className="text-xs font-light">
                              {slider.text}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </Slider>
          </div>
          {/* <div
            className="w-full h-full bg-gradient-to-b from-transparent via-transparent to-primaryDark/60 relative"
            style={{ zIndex: 10 }}
          >
            <AuthSlider sliderRef={sliderRef} />
          </div> */}
        </div>
        <div
          className="absolute -bottom-8 -right-8 w-52 h-80 p-1 bg-gradient-to-l from-primary to-primaryLight rounded-tl-[4rem] rounded-br-[4rem] overflow-hidden"
          style={{ zIndex: 0 }}
        >
          <div className="bg-white w-full h-full rounded-tl-[3.8rem] rounded-br-[3.8rem]" />
        </div>
      </div>
    </div>
  );
};

export default AuthSliderWrapper;
