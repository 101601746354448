import { homnifiLogo } from '../../images';
import { useLocation, useNavigate } from 'react-router-dom';
import { useUserActions } from '../../store/user/userActions';
import { selectUserSlice } from '../../store/user/userSlice';
import { useSelector } from 'react-redux';
import { PLATFORM_NAME } from '../../utils/constants';

const AuthHeader = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { userLogout } = useUserActions();
  const { user } = useSelector(selectUserSlice);

  return location.pathname === '/auth/signin' ? (
    // For Signup
    <div className="w-full flex sm:flex-row flex-col gap-4 justify-between text-sm py-4 px-0">
      <img src={homnifiLogo} alt={`${PLATFORM_NAME} Logo`} className="w-32 cursor-pointer" onClick={() => window.location.href = "https://homnifi.com"} />
      <div className="xs:w-auto w-full">
        Don't have any account yet?{' '}
        <button
          onClick={() => navigate('/auth/signup')}
          className="font-medium text-primary"
        >
          Sign Up
        </button>
      </div>
    </div>
  ) : (
    // For Login
    <div className="w-full flex sm:flex-row flex-col gap-4 justify-between text-sm py-4 px-0">
      <img src={homnifiLogo} alt={`${PLATFORM_NAME} Logo`} className="w-32 cursor-pointer" onClick={() => window.location.href = "https://homnifi.com"} />
      {!user ? (
        <div className="xs:w-auto w-full">
          Already have an account?{' '}
          <button
            onClick={() => {
              // userLogout();
              navigate('/auth/signin');
            }}
            className="font-medium text-primary"
          >
            Sign In
          </button>
        </div>
      ) : (
        <div className="xs:w-auto w-full">
          Not {user?.email}?{' '}
          <button
            onClick={userLogout}
            className="font-medium text-primary"
          >
            Logout
          </button>
        </div>
      )}
    </div>
  );
};

export default AuthHeader;
