import { StrictMode, useEffect } from 'react';
import { createRoot } from 'react-dom/client';
import { RouterProvider } from 'react-router-dom';
import { Provider } from 'react-redux';
import { Bounce, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './styles/index.css';
import { NextUIProvider } from '@nextui-org/react';
import LogoutModal from './components/Modal/LogoutModal';
import { store } from './store';
import { router } from './utils/routers';
import { PLATFORM_ENVIRONMENT } from './utils/constants';

const App = () => {
  useEffect(() => {
    if (PLATFORM_ENVIRONMENT !== 'DEV') {
      const script = document.createElement('script');
      script.src = 'https://www.googletagmanager.com/gtag/js?id=G-9QJGRHQ6NH';
      script.async = true;
      document.head.appendChild(script);

      // Create the inline script element
      const inlineScript = document.createElement('script');
      inlineScript.innerHTML = `
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', 'G-9QJGRHQ6NH');
      `;
      document.head.appendChild(inlineScript);
    }
  }, []);

  return (
    <NextUIProvider>
      <Provider store={store}>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={true}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
          transition={Bounce}
        />
        <LogoutModal />
        <RouterProvider router={router} />
      </Provider>
    </NextUIProvider>
  );
};

const root = createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <StrictMode>
    <App />
  </StrictMode>
);
