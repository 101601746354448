import { AnimatePresence, motion } from 'framer-motion';
import React, { useEffect } from 'react';
import { notificationCode } from '../Notifications/TabContent';
import { useDispatch, useSelector } from 'react-redux';
import {
  removeNewNotification,
  selectNotification,
} from '../../store/notifications/notificationsSlice';
import { ReactComponent as Close } from '../../SVGIcons/close.svg';
import { ReactComponent as ProfileIcon } from '../../SVGIcons/Notifications/profile.svg';

const Notifications = () => {
  //   const newNotificationList = { message: 'i am a new notification' };
  const { newNotificationList } = useSelector(selectNotification);
  const dispatch = useDispatch();

  const timeAgo = (date: Date) => {
    const now = new Date();
    const seconds = Math.floor((now.getTime() - date.getTime()) / 1000);
    const interval = Math.floor(seconds / 60);

    if (interval < 1) return 'now';
    if (interval < 60)
      return `${interval} minute${interval > 1 ? 's' : ''} ago`;
    if (interval < 1440)
      return `${Math.floor(interval / 60)} hour${Math.floor(interval / 60) > 1 ? 's' : ''} ago`;
    return `${Math.floor(interval / 1440)} day${Math.floor(interval / 1440) > 1 ? 's' : ''} ago`;
  };
  useEffect(() => {
    if (newNotificationList.length > 0) {
      const timer = setTimeout(() => {
        dispatch(removeNewNotification(-1));
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, [newNotificationList, dispatch]);

  //  const [showPopup, setShowPopup] = useState(true);
  return (
    <div className=" fixed top-20 left-1/2 transform -translate-x-1/2 z-[99999999] flex flex-col-reverse gap-3 xxs:w-full sm:w-auto px-4 md:max-w-[80%] md:px-0">
      <AnimatePresence>
        {newNotificationList.map((notification, index) => (
          <motion.div
            key={notification.id}
            initial={{ opacity: 0, y: -100 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -100 }}
            transition={{
              type: 'spring',
              stiffness: 100,
              damping: 20,
              duration: 0.5,
            }}
            className=" backdrop-filter backdrop-blur-lg bg-opacity-60  shadow-notificationShadow flex justify-center items-center gap-4 p-4  border border-1 border-borderBlue rounded-lg w-full md:w-auto "
          >
            <button
              onClick={() => dispatch(removeNewNotification(notification.id))}
              className="absolute top-1 right-1 p-1  "
            >
              <Close className="h-3 w-3 text-black" />
            </button>
            {/* <div className="shadow-notificationIconShadow w-9 h-9 rounded-full flex justify-center items-center bg-white border border-1 border-borderBlue"> */}
            <div className="w-10 h-10 flex justify-center items-center">
              {notificationCode[notification?.type] ?? <ProfileIcon />}{' '}
            </div>
            <span className="text-sm font-normal text-black">
              {notification.message}
            </span>
            <span className="text-xs font-normal text-primaryGrey">
              {timeAgo(new Date())}
            </span>
          </motion.div>
        ))}
      </AnimatePresence>
    </div>
  );
};

export default Notifications;
