import UniversalCookie from 'universal-cookie';
import { ACCESS_TOKEN, LOGIN_TOKEN } from '../constants';
import currentStorage from '../currentStorage';

const cookie = new UniversalCookie();

const COOKIE_EXPIRATION_DAYS = 7;

export const setAccessTokenCookie = (accessToken: string) => {
  cookie.set('access_token', JSON.stringify(accessToken), {
    path: '/',
    expires: new Date(
      new Date().getTime() + COOKIE_EXPIRATION_DAYS * 24 * 60 * 60 * 1000
    ),
    secure: process.env.NODE_ENV === 'production', // Enable secure flag in production
    httpOnly: false,
  });
};

export const setLoginToken = (loginToken: string) => {
  const storage = currentStorage();
  storage.set('loginToken', loginToken);
};

export const setStorage = (key: string, value: any) => {
  const storage = currentStorage();
  storage.setItem(key, value);
};

export const getLoginToken = () => {
  const storage = currentStorage();
  return storage.getItem(LOGIN_TOKEN);
};
export const getAccessToken = () => {
  const storage = currentStorage();
  return storage.getItem(ACCESS_TOKEN);
};

export const setLoginTokenCookie = (loginToken: string) => {
  cookie.set('loginToken', JSON.stringify(loginToken), {
    path: '/',
    expires: new Date(
      new Date().getTime() + COOKIE_EXPIRATION_DAYS * 24 * 60 * 60 * 1000
    ),
    secure: process.env.NODE_ENV === 'production', // Enable secure flag in production
    httpOnly: false,
  });
};

export const getLoginTokenCookie = () => {
  return cookie.get('loginToken');
};

export const getAccessTokenFromCookie = () => {
  return cookie.get('access_token');
};

export const removeAccessTokenCookie = () => {
  cookie.remove('access_token', { path: '/' });
};

export const setRefreshTokenCookie = (refreshToken: string) => {
  cookie.set('refresh_token', refreshToken, {
    path: '/',
    expires: new Date(
      new Date().getTime() + COOKIE_EXPIRATION_DAYS * 24 * 60 * 60 * 1000
    ),
    secure: process.env.NODE_ENV === 'production',
  });
};

export const getRefreshTokenFromCookie = () => {
  return cookie.get('refresh_token');
};

export const removeRefreshTokenCookie = () => {
  cookie.remove('refresh_token', { path: '/' });
};

export const setUserCookie = (user: any) => {
  cookie.set('user', JSON.stringify(user), {
    path: '/',
    expires: new Date(
      new Date().getTime() + COOKIE_EXPIRATION_DAYS * 24 * 60 * 60 * 1000
    ),
    secure: process.env.NODE_ENV === 'production',
    httpOnly: false,
  });
};

export const removeUserCookie = () => {
  cookie.remove('user', { path: '/' });
};

export const clearStorage = () => {
  const storage = currentStorage();
  storage.clear();
};
