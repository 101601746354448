import React, { useEffect } from 'react';
import GlobalHeader from '../Global/GlobalHeader';
import CardLayout from '../Cards/CardLayout.tsx';
import GlobalSlider from '../Global/GlobalSlider';
import PieChart from '../PieChart';
import { TriangleIcon } from '../../images/svgs';
import { RewardsCalculatorChartDefs } from '../../utils/data/LineChartData';
import LineChart from './LineChart';
import { useDispatch, useSelector } from 'react-redux';
import {
  rewardsSliceAction,
  selectRewardSlice,
} from '../../store/Cloudk2/rewardclaculator.slice';
import { useGeneralActions } from '../../store/general/generalActions';
import { selectConvertedPrice } from '../../store/general/generalSlice';
import CustomSwitch from '../Global/GlobalSwitch';
import { selectCloudSlice } from '../../store/Cloudk2/clouldk2Slice';
import { useCloudActions } from '../../store/Cloudk2/cloudk2Actions';
import {
  getFinalCompoundedValue,
  getRewardsPercentage,
} from '../../utils/helper';
import RenderRewardsMessage from '../../pages/Cloud2/_components/RenderRewardsMessage';


const RewardsCalculator = () => {
  const dispatch = useDispatch();
  const {
    selectedProduct,
    years,
    selectedYears,
    stackAmount,
    autoCompound,
    rewardsCalculatorChartData,
  } = useSelector(selectRewardSlice);
  const convertedPrice = useSelector(selectConvertedPrice);
  const { GetConvertedPrice } = useGeneralActions();
  const { userProducts } = useSelector(selectCloudSlice);

  const { getAllProducts } = useCloudActions();
  React.useEffect(() => {
    GetConvertedPrice();
    getAllProducts();
  }, []);

  useEffect(() => {
    if (userProducts) {
      if (userProducts?.length > 0 && !selectedProduct) {
        dispatch(
          rewardsSliceAction.setRewardSlice({
            selectedProduct: userProducts[0],
          })
        );
      }
    }
  }, [userProducts, dispatch, selectedProduct]);

// Getting Compound Value ---

  const reward = getFinalCompoundedValue(
    autoCompound,
    stackAmount,
    selectedProduct?.mintingPowerPerc,
    selectedYears
  );

  return (
    <section className="w-full flex flex-col gap-10">
      <article className="flex items-center justify-between">
        <GlobalHeader title="Rewards Simulator" />
      </article>

      <article className="col-span-12 flex flex-col gap-3">
        <span className="text-base text-primaryDark font-medium">
          Select a Minter
        </span>

        <div className="flex items-center gap-3 flex-wrap">
          {(userProducts || []).map((product, index) => (
            <button
              type="button"
              className={`text-base font-normal px-5 py-2 rounded-md w-max ${
                product.name === selectedProduct?.name
                  ? 'bg-primary/10 text-primary'
                  : 'text-primaryGrey border border-blueGrey'
              }`}
              key={index}
              onClick={() => {
                dispatch(
                  rewardsSliceAction.setRewardSlice({
                    selectedProduct: product,
                  })
                );
              }}
            >
              {product.name}
            </button>
          ))}
        </div>
      </article>

      <article className="flex flex-col gap-3">
        <CardLayout className="!rounded-md bg-white p-5 flex flex-col gap-3">
          <section className="grid grid-cols-2 gap-5 h-full">
            <article className="md:col-span-1 col-span-2 flex flex-col gap-2">
              <div className="flex items-center justify-between gap-2 mt-4">
                <span className="text-defaultBlack text-xl font-medium ">
                  $ {stackAmount}
                </span>

                <span className="text-defaultBlack text-xl font-medium">
                  ≈ {Number(stackAmount / convertedPrice)?.toFixed(5)} mLYK
                </span>
              </div>

              <GlobalSlider
                maxValue={selectedProduct?.stakeUnlimited === false ? selectedProduct?.stakeLimit : 999999999}
                currentValue={1}
                minValue={1}
                getCurrentValue={(value) => {
                  dispatch(rewardsSliceAction.changeStakeAmount(value));
                }}
                baseClassName="!w-full !col-span-2 !max-w-full"
              />

              <div className="flex items-center flex-wrap gap-3">
                {years.map((year: any, index: number) => (
                  <button
                    type="button"
                    className={`text-xs flex flex-col items-center gap-1 font-normal px-5 py-2 rounded-full w-max ${
                      year === selectedYears
                        ? 'bg-primary text-white'
                        : 'text-primaryGrey border border-blueGrey'
                    }`}
                    key={index}
                    onClick={() => {
                      dispatch(rewardsSliceAction.selectYears(year));
                    }}
                  >
                    <span className="font-medium uppercase">{year} Year</span>
                    {/* <span>{tab.price}</span> */}
                  </button>
                ))}
              </div>
              <article className="flex items-center gap-2 pt-10">
                <span className="text-primaryDark text-lg font-medium">
                  Auto-Link
                </span>
                <CustomSwitch
                  checked={autoCompound}
                  onChange={(checked) =>
                    dispatch(rewardsSliceAction.setAutoCompound(checked))
                  }
                />
              </article>
            </article>

            <article className="md:col-span-1 h-full col-span-2 flex flex-col justify-end items-center">
              <PieChart
                className="w-full self-end h-full "
                data={[
                  {
                    id: 'staked-amount',
                    label: 'Staked Amount',
                    value: stackAmount,
                    color: '#0E173F',
                  },
                  // {
                  //   id: 'total-loss',
                  //   label: 'Total Loss',
                  //   value: totalLoss,
                  //   color: '#1B9FDA',
                  // },
                  {
                    id: 'total-profit',
                    label: 'Total Profit',
                    value: reward,
                    color: '#1B9FDA',
                  },
                ]}
                enableArcLinkLabels={false}
                colors={['#0E173F', '#1B9FDA']}
                valueFormat={(e: any) => '$' + e}
                innerRadius={0.75}
                enableArcLabels={false}
                enableCustomLegend={true}
                RenderBottom={
                  <article className="flex items-center justify-between">
                    <div className="flex items-center justify-between gap-8">
                      <div className="flex flex-col items-center justify-center">
                        <span className="text-sm text-primaryGrey font-medium">
                          $ {Math.round(stackAmount)?.toFixed(2)} Would have
                          become
                        </span>
                        <span className="text-base text-defaultBlack font-semibold">
                          $ {(reward + stackAmount)?.toFixed(2)}
                        </span>
                      </div>
                      <span
                        className={`text-primaryGreen text-sm font-medium flex items-center gap-2`}
                      >
                        <TriangleIcon className="w-5 h-5" />
                        {getRewardsPercentage(reward, stackAmount)?.toFixed(2)} %
                      </span>
                    </div>
                  </article>
                }
              />
            </article>
            <article className="col-span-2 h-full p-4 border border-chartsCardBorder rounded-lg shadow-chartCardShadow">
              <LineChart
                data={rewardsCalculatorChartData}
                defs={RewardsCalculatorChartDefs}
                margin={{
                  top: 30,
                  right: 20,
                  bottom: 50,
                  left: 100,
                }}
                xLegend={selectedYears * 12 + ' Months'}
                enableCustomtooltip={true}
                colors={['#F23838', '#3B89FF', '#FFD16C', '#12B76A']}
                containerClassName="w-full h-[30rem] overflow-x-auto sm:overflow-visible"
              />
            </article>
          </section>
        </CardLayout>
        <RenderRewardsMessage />
      </article>
    </section>
  );
};

export default RewardsCalculator;
