//REDUX-TOOLKIT
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TOKEN_ROUND_ICONS } from '../../utils/allConstants';

export interface TOKEN {
  name: string;
  value: string;
  icon: any;
}

export const TOKEN_LYK_D: TOKEN = {
  name: 'LYK - D',
  value: 'LYK - D',
  icon: TOKEN_ROUND_ICONS['USDT'],
};

export interface StateDeposit {
  coinList: TOKEN[];
  selectedCoin: TOKEN | null;
  networkList: any[];
  selectedNetwork: string | null;
  depositAddress: string;
  currentStep: 1 | 2 | 3 | 4;
}

export const DEPOSIT_SLICE_NAME = 'deposit';

const initialState: StateDeposit = {
  coinList: [],
  selectedCoin: null,
  networkList: [],
  selectedNetwork: '',
  depositAddress: '0x2sdf65xc521sadf650sfd231654asdf',
  currentStep: 1,
};

const slice = createSlice({
  name: DEPOSIT_SLICE_NAME,
  initialState,
  reducers: {
    setDepositSlice: (state, action: PayloadAction<any>) => {
      return { ...state, ...action.payload };
    },
  },
});

export const depositSliceAction = slice.actions;
export default slice.reducer;
