import {
  CLOUDK_TRANSACTIONS,
  USER_GLOBAL_CLAIM_REWARDS,
  USER_GLOBAL_MACHINE_AUTO_COMPOUND,
  USER_PRODUCTS,
  USER_STAKE_INTO_MACHINE,
} from './../../utils/network/ApiEndpoints';
import { useDispatch } from 'react-redux';
import {
  USER_GLOBAL_AUTO_COMPOUND,
  USER_MACHINES,
  USER_MACHINES_BY_ID,
  USER_REWARDS,
} from '../../utils/network/ApiEndpoints';
import ApiRequestHomnifi from '../../utils/network/ApiRequestHomnifi';
import { setClouldk2Slice } from './clouldk2Slice';
import { toast } from 'react-toastify';
import { store } from '..';
import {
  TCloudSetAutoCompoundRequest,
  TCloudSetGlobalMachineAutoCompound,
  TPostStakeIntoMachine,
  TPostUserGlobalClaimRewards,
} from '../../types/cloundk.type';

export const useCloudActions = () => {
  const dispatch = useDispatch();

  const getUserRewardsData = async () => {
    dispatch(
      setClouldk2Slice({
        userRewardsLoading: true,
      })
    );

    return await ApiRequestHomnifi()
      .request({
        method: 'GET',
        url: USER_REWARDS,
      })
      .then((response) => {
        dispatch(
          setClouldk2Slice({
            userRewards: response.data.data,
            userRewardsLoading: false,
          })
        );

        return response;
      })
      .catch((error) => {
        dispatch(
          setClouldk2Slice({
            userRewardsLoading: false,
          })
        );

        if (error.data) {
          toast.error(error.data.message);
        }

        return error;
      });
  };

  const getUserMachinesList = async (loading = true) => {
    if (loading) {
      dispatch(
        setClouldk2Slice({
          userMachinesLoading: true,
        })
      );
    }

    return await ApiRequestHomnifi()
      .request({
        method: 'GET',
        url: USER_MACHINES,
      })
      .then((response: any) => {
        dispatch(
          setClouldk2Slice({
            machineList: [...response.data.data],
          })
        );

        if (loading) {
          dispatch(
            setClouldk2Slice({
              userMachinesLoading: false,
            })
          );
        }

        return response;
      })
      .catch((error) => {
        if (loading) {
          dispatch(
            setClouldk2Slice({
              userMachinesLoading: false,
            })
          );
        }

        if (error.data) {
          toast.error(error.data.message);
        }

        return error;
      });
  };

  const getUserMachineById = async ({ id }: { id: string }) => {
    dispatch(
      setClouldk2Slice({
        userMachineByIdLoading: true,
      })
    );

    return await ApiRequestHomnifi()
      .request({
        method: 'GET',
        url: `${USER_MACHINES_BY_ID}?machine=${id}`,
        data: id,
      })
      .then((response: any) => {
        const machine = store
          .getState()
          .clouldk2.machineList.map((_machine: any) =>
            _machine._id === id
              ? {
                  ..._machine,
                  ...response.data.data,
                }
              : _machine
          );

        dispatch(
          setClouldk2Slice({
            machineList: machine,
            userMachineByIdLoading: false,
          })
        );

        return response;
      })
      .catch((error) => {
        dispatch(
          setClouldk2Slice({
            userMachineByIdLoading: false,
          })
        );

        if (error.data) {
          toast.error(error.data.message);
        }

        return error;
      });
  };

  const getGlobalAutoCompound = async () => {
    dispatch(
      setClouldk2Slice({
        userGlobalAutoCompoundLoading: true,
      })
    );

    return await ApiRequestHomnifi()
      .request({
        method: 'GET',
        url: USER_GLOBAL_AUTO_COMPOUND,
      })
      .then((response: any) => {
        dispatch(
          setClouldk2Slice({
            globalAutoCompound: response.data.data.enabled,
          })
        );
        dispatch(
          setClouldk2Slice({
            userGlobalAutoCompoundLoading: false,
          })
        );

        return response;
      })
      .catch((error) => {
        dispatch(
          setClouldk2Slice({
            userGlobalAutoCompoundLoading: false,
          })
        );

        if (error.data) {
          toast.error(error.data.message);
        }

        return error;
      });
  };

  const setGlobalAutoCompound = async (data: TCloudSetAutoCompoundRequest) => {
    dispatch(
      setClouldk2Slice({
        userGlobalAutoCompoundLoading: true,
      })
    );

    return await ApiRequestHomnifi()
      .request({
        method: 'POST',
        url: USER_GLOBAL_AUTO_COMPOUND,
        data,
      })
      .then((response: any) => {
        dispatch(
          setClouldk2Slice({
            globalAutoCompound: data.enabled,
          })
        );
        dispatch(
          setClouldk2Slice({
            userGlobalAutoCompoundLoading: false,
          })
        );

        return response;
      })
      .catch((error) => {
        dispatch(
          setClouldk2Slice({
            userGlobalAutoCompoundLoading: false,
          })
        );

        if (error.data) {
          toast.error(error.data.message);
        }
        return error;
      });
  };

  const setGlobalMachineAutoCompound = async (
    data: TCloudSetGlobalMachineAutoCompound
  ) => {
    dispatch(
      setClouldk2Slice({
        setGlobalMachineAutoCompoundLoading: true,
      })
    );

    return await ApiRequestHomnifi()
      .request({
        method: 'POST',
        url: USER_GLOBAL_MACHINE_AUTO_COMPOUND,
        data,
      })
      .then((response: any) => {
        dispatch(
          setClouldk2Slice({
            setGlobalMachineAutoCompoundLoading: false,
          })
        );

        return response;
      })
      .catch((error: any) => {
        dispatch(
          setClouldk2Slice({
            setGlobalMachineAutoCompoundLoading: false,
          })
        );

        if (error.data) {
          toast.error(error.data.message);
        }
        return error;
      });
  };

  const postUserGlobalClaimRewards = async (
    data: TPostUserGlobalClaimRewards
  ) => {
    dispatch(
      setClouldk2Slice({
        userGlobalClaimRewardsLoading: true,
      })
    );

    return await ApiRequestHomnifi()
      .request({
        method: 'POST',
        url: USER_GLOBAL_CLAIM_REWARDS,
        data,
      })
      .then((response: any) => {
        dispatch(
          setClouldk2Slice({
            userGlobalClaimRewardsLoading: false,
          })
        );

        if (response.data.data === 'Nothing to claim')
          toast.info(response.data.data);

        return response;
      })
      .catch((error: any) => {
        dispatch(
          setClouldk2Slice({
            userGlobalClaimRewardsLoading: false,
          })
        );

        if (error.data) {
          toast.error(error.data);
        }
        return error;
      });
  };

  const getUserMachineRewards = async ({
    limit,
    page,
    storeRedux = true,
    search,
    token,
    machine,
    date,
    type,
    fromDate,
    toDate,
  }: {
    limit: number;
    page: number;
    storeRedux?: boolean;
    search?: string;
    token?: string;
    date?: string;
    type?: string;
    machine?: string;
    fromDate?: any;
    toDate?: any;
  }) => {
    dispatch(
      setClouldk2Slice({
        userTransactionHistoryLoading: true,
      })
    );

    return await ApiRequestHomnifi()
      .request({
        method: 'GET',
        url: CLOUDK_TRANSACTIONS,
        params: {
          limit,
          page,
          search,
          token,
          type,
          machine,
          fromDate,
          toDate,
        },
      })
      .then((response: any) => {
        if (storeRedux) {
          dispatch(
            setClouldk2Slice({
              userTransactionHistory: response.data.data.list,
            })
          );
        }
        dispatch(
          setClouldk2Slice({
            userTransactionHistoryLoading: false,
          })
        );

        return response;
      })
      .catch((error: any) => {
        dispatch(
          setClouldk2Slice({
            userTransactionHistoryLoading: false,
          })
        );

        if (error.data) {
          toast.error(error.data);
        }
        return error;
      });
  };

  const postStakeIntoMachine = async (data: TPostStakeIntoMachine) => {
    dispatch(
      setClouldk2Slice({
        userGlobalClaimRewardsLoading: true,
      })
    );

    return await ApiRequestHomnifi()
      .request({
        method: 'POST',
        url: USER_STAKE_INTO_MACHINE,
        data,
      })
      .then((response: any) => {
        dispatch(
          setClouldk2Slice({
            userGlobalClaimRewardsLoading: false,
          })
        );

        getUserMachinesList();

        getUserRewardsData();

        return response;
      })
      .catch((error: any) => {
        dispatch(
          setClouldk2Slice({
            userGlobalClaimRewardsLoading: false,
          })
        );

        if (error.data) {
          toast.error(error.data.message);
        } else {
          toast.error('Something went wrong!');
        }

        return error;
      });
  };

  const getAllProducts = async () => {
    dispatch(
      setClouldk2Slice({
        getAllProductsLoading: true,
      })
    );

    return await ApiRequestHomnifi()
      .request({
        method: 'GET',
        url: USER_PRODUCTS,
      })
      .then((response: any) => {
        dispatch(
          setClouldk2Slice({
            userProducts: response.data.data,
          })
        );
        dispatch(
          setClouldk2Slice({
            getAllProductsLoading: false,
          })
        );

        return response;
      })
      .catch((error: any) => {
        dispatch(
          setClouldk2Slice({
            getAllProductsLoading: false,
          })
        );

        if (error.data) {
          toast.error(error.data);
        }
        return error;
      });
  };

  return {
    getUserRewardsData,
    getUserMachinesList,
    getUserMachineById,
    getGlobalAutoCompound,
    setGlobalAutoCompound,
    postUserGlobalClaimRewards,
    setGlobalMachineAutoCompound,
    getUserMachineRewards,
    postStakeIntoMachine,
    getAllProducts,
  };
};
