//REDUX-TOOLKIT
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../index';

const name = 'walletData';

export type TPaginated = {
  totalPages: number;
  currentPage: number;
  totalCount: number;
};

export type TWithdrawAndTransferHistory = {
  list: {
    _id: string;
    id: number;
    time: string;
    type: string;
    withdrawToken: string;
    amount: number;
    destination: string;
    status: string;
    requestId: string;
  }[];
} & TPaginated;

export type TDepositHistory = {
  list: {
    id: string;
    time: string;
    type: string;
    amount: number;
    hash: string;
    status: string;
  }[];
} & TPaginated;

export type TSwapHistory = {
  list: {
    requestId: string;
    type: string;
    time: string;
    fromToken: string;
    toToken: string;
    amount: string;
    status: string;
  }[];
} & TPaginated;

type State = {
  walletList: any;
  networkList: any[];
  withdrawHistory: any[];
  transferHistory: any[];
  withdrawAndTransferHistory?: TWithdrawAndTransferHistory;
  swapHistory?: TSwapHistory;
  depositHistory?: TDepositHistory;
  trxSettings: any;
  allTokens: any[];
  withdrawFaqs: any;
  transferFaqs: any;
  swapFaqs: any;
  depositFaqs: any;
  depositAddress: any;
  withdrawSettings: any;
  swapSettings: any;
  depositSettings: any;
  loading: boolean;

  setWalletDataWithdrawAndTransferHistoryLoading?: boolean;
  getWalletDataDepositHistoryLoading?: boolean;
  getWalletDataSwapHistoryLoading?: boolean;
  qrCodeLoader: boolean;
};

const initialState: State = {
  walletList: [],
  networkList: [],
  withdrawHistory: [],
  transferHistory: [],
  trxSettings: null,
  allTokens: [],
  withdrawFaqs: null,
  transferFaqs: null,
  swapFaqs: null,
  depositFaqs: null,
  depositAddress: null,
  withdrawSettings: null,
  swapSettings: null,
  depositSettings: null,
  loading: false,

  setWalletDataWithdrawAndTransferHistoryLoading: false,
  getWalletDataDepositHistoryLoading: false,
  getWalletDataSwapHistoryLoading: false,
  qrCodeLoader: false,
};

const slice = createSlice({
  name,
  initialState,
  reducers: {
    setWalletDataWalletsList: (state: State, action: PayloadAction<any>) => {
      return { ...state, walletList: action.payload };
    },
    setWalletDataNetworksList: (state: State, action: PayloadAction<any>) => {
      return { ...state, networkList: action.payload };
    },
    setWalletDataWithdrawHistory: (
      state: State,
      action: PayloadAction<any>
    ) => {
      return { ...state, withdrawHistory: action.payload };
    },
    setWalletDataTransferHistory: (
      state: State,
      action: PayloadAction<any>
    ) => {
      return { ...state, transferHistory: action.payload };
    },
    setWalletDataWithdrawAndTransferHistory: (
      state: State,
      action: PayloadAction<any>
    ) => {
      return { ...state, withdrawAndTransferHistory: action.payload };
    },
    setWalletDataSwapHistory: (state: State, action: PayloadAction<any>) => {
      return { ...state, swapHistory: action.payload };
    },
    setWalletDataDepositHistory: (state: State, action: PayloadAction<any>) => {
      return { ...state, depositHistory: action.payload };
    },
    setWalletDataTrxSettings: (state: State, action: PayloadAction<any>) => {
      return { ...state, trxSettings: action.payload };
    },
    setWalletDataAllTokens: (state: State, action: PayloadAction<any>) => {
      return { ...state, allTokens: action.payload };
    },
    setWalletDataWithdrawFaqs: (state: State, action: PayloadAction<any>) => {
      return { ...state, withdrawFaqs: action.payload };
    },
    setWalletDataTransferFaqs: (state: State, action: PayloadAction<any>) => {
      return { ...state, transferFaqs: action.payload };
    },
    setWalletDataSwapFaqs: (state: State, action: PayloadAction<any>) => {
      return { ...state, swapFaqs: action.payload };
    },
    setWalletDataDepositFaqs: (state: State, action: PayloadAction<any>) => {
      return { ...state, depositFaqs: action.payload };
    },
    setDepositAddress: (state: State, action: PayloadAction<any>) => {
      return { ...state, depositAddress: action.payload };
    },
    setQRLoader: (state: State, action: PayloadAction<any>) => {
      return { ...state, qrCodeLoader: action.payload };
    },
    setWithdrawSettings: (state: State, action: PayloadAction<any>) => {
      return { ...state, withdrawSettings: action.payload };
    },
    setSwapSettings: (state: State, action: PayloadAction<any>) => {
      return { ...state, swapSettings: action.payload };
    },
    setDepositSettings: (state: State, action: PayloadAction<any>) => {
      return { ...state, depositSettings: action.payload };
    },
    setLoading: (state: State, action: PayloadAction<any>) => {
      return { ...state, loading: action.payload };
    },
    setWalletDataSlice: (state: State, action: PayloadAction<any>) => {
      return { ...state, ...action.payload };
    },
  },
});

export const WALLETDATA_SLICE_NAME = name;
export const {
  setWalletDataWalletsList,
  setWalletDataNetworksList,
  setWalletDataWithdrawHistory,
  setWalletDataTransferHistory,
  setWalletDataWithdrawAndTransferHistory,
  setWalletDataSwapHistory,
  setWalletDataDepositHistory,
  setWalletDataTrxSettings,
  setWalletDataAllTokens,
  setWalletDataWithdrawFaqs,
  setWalletDataTransferFaqs,
  setWalletDataSwapFaqs,
  setWalletDataDepositFaqs,
  setDepositAddress,
  setWithdrawSettings,
  setSwapSettings,
  setDepositSettings,
  setWalletDataSlice,
  setLoading,
  setQRLoader,
} = slice.actions;
export const selectWalletData = (state: RootState) =>
  state[WALLETDATA_SLICE_NAME];
export default slice.reducer;
