import axios, { AxiosError, AxiosResponse } from 'axios';
import {
  API_INSTANCE_HOMNIFI,
} from './ApiEndpoints';
import {
  clearStorage,
  getAccessToken,
  getLoginToken,
} from '../storage/protectedStorage';
import { ACCESS_TOKEN, LOGIN_TOKEN } from '../constants';
import { PAGES } from '../routers';
import currentStorage from '../currentStorage';
import { store } from '../../store';
import { reset } from '../../store/user/userSlice';
import { showModal } from '../../store/modal/modalSlice';

const logout = () => {
  store.dispatch(reset());

  const storage = currentStorage();
  storage.removeItem(LOGIN_TOKEN);
  storage.removeItem(ACCESS_TOKEN);
  localStorage.removeItem('valuesOfRememberMe');
  clearStorage();
  localStorage.clear();
  sessionStorage.clear();
  window.location.href = PAGES.LOGIN;
};

export default function ApiRequestHomnifi() {
  const accessToken = getAccessToken();
  const loginToken = getLoginToken();

  const request = axios.create({
    // baseURL: `${API_INSTANCE_HOMNIFI}`,
    baseURL: `${API_INSTANCE_HOMNIFI}`,
    headers: {
      Authorization: `Bearer ${accessToken ? accessToken : null}`,
      Bidtoken: `Token ${loginToken ? loginToken : null}`,
    },
    responseType: 'json',
    socketPath: null,
  });

  request.interceptors.response.use(
    (response: AxiosResponse): AxiosResponse => response,
    (error: AxiosError): Promise<AxiosError> | AxiosError => {
      if (error.code === 'ERR_NETWORK') {
        return error;
      }

      if (error.response?.status === 401) {
        store.dispatch(showModal());
        return error;
      }

      if (error.response?.status === 403) {
        store.dispatch(showModal());
        return error;
      }

      if (error.response?.status === 503) {
        window.location.href = '/maintenance';
        // navigate('/maintenance');
        return error;
      }

      return Promise.reject(error.response);
    }
  );

  return request;
}
