import { Slider } from '@nextui-org/slider';
import React from 'react';

type Props = {
  minValue?: number;
  maxValue?: number;
  currentValue?: number;
  strict?: boolean;
  getCurrentValue?: (arg: string) => void;
  baseClassName?: string;
  trackWrapperClassName?: string;
};

const GlobalSlider = ({
  minValue,
  maxValue,
  getCurrentValue,
  currentValue,
  baseClassName,
  trackWrapperClassName,
}: Props) => {
  const [value, setValue] = React.useState(currentValue || 100);

  const handleChange = (value: any) => {
    if (value) if (isNaN(Number(value))) return;

    setValue(value);
    getCurrentValue && getCurrentValue(value);
  };

  return (
    <Slider
      size="sm"
      classNames={{
        base: `w-full gap-3 ${baseClassName}`,
        track: `bg-gradient-to-r from-[#00A0E3] to-[#0E173F] border-x-0`,
        filler: 'bg-transparent',
        thumb: 'bg-white border-2 border-primary/40',
        trackWrapper: `${trackWrapperClassName}`,
      }}
      step={0.01}
      maxValue={maxValue}
      minValue={minValue}
      aria-label="Temperature"
      defaultValue={0.2}
      className="w-full"
      value={value}
      onChange={handleChange}
    />
  );
};

export default GlobalSlider;
