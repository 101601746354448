import { Socket } from 'socket.io-client';

export enum SocketIOSConnectionStatus {
  CONNECTED = 'connected',
  CONNECTING = 'connecting',
  ERROR = 'error',
  DISCONNECTED = 'disconnect',
}

export interface SocketIOInitialState {
  socket: Socket | null;
  isConnected: boolean;
  isDisconnected: boolean;
  isConnecting: boolean;
  isError: boolean;
  error: string;
  status: SocketIOSConnectionStatus;
  disconnectReason: string | undefined;
}

export type SocketEventOptions = {
  once?: boolean;
};
