import React, { useEffect, useState } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
import { Link, useLocation } from 'react-router-dom';
import GlobalIconWrap from '../Global/GlobalIconWrap';
import { useDispatch, useSelector } from 'react-redux';
import { toggleDrawer } from '../../store/drawer/drawerSlice';
import {
  UserIcon,
  Hamburger,
  Logo,
  NotificationIcon,
  NewsLetterIcon,
  QrCodeIcon,
  VerifiedIcon,
} from '../../images/svgs';
import NotificationsPopOver from '../Notifications/NotificationsPopOver';
import { AnimatePresence, motion } from 'framer-motion';
import ProfilePopOver from '../Profile/ProfilePopOver';
import ProfileQR from './ProfileQR';
import {
  selectNotification,
  setIsNotificationOpen,
  setNewNotification,
} from '../../store/notifications/notificationsSlice';
import { useNotificationsActions } from '../../store/notifications/notificationActions';
import GlobalAutoComplete from '../Global/GlobalAutoComplete';
import { useWindowSize } from '../../utils/helper';
import useNotificationToggler from '../../hooks/useNotificationToggler';
import GlobalModal from '../Global/GlobalModal';
import { useUserActions } from '../../store/user/userActions';
import { setUserSlice } from '../../store/user/userSlice';
import CustomSwitch from '../Global/GlobalSwitch';
import GlobalButton from '../Global/GlobalButton';
import { getAccessToken } from '../../utils/storage/protectedStorage';
import { toast } from 'react-toastify';
import useSocketEvent from '../../hooks/socketIO/useSocketEvent';
import {
  DepositTransactionResponse,
  NewNotificationType,
} from '../../types/wallet.types';
import { TransactionEvents } from '../../utils/allConstants';

import useUserCheck from '../../hooks/useUserCheck';
import useCopyTextHook from '../../hooks/useCopyTextHook';
import { copy, tick } from '../../images/others';
import { notificationCode } from '../Notifications/TabContent';
import Notifications from '../common/Notifications';
import { selectSuperNodeSlice } from '../../store/superNode/superNodeSlice';
import NewsletterPopOver from '../Notifications/NewsletterPopOver';
import { useNewsletterActions } from '../../store/newsLetter/newLetterActions';
import useNewsletterToggler from '../../hooks/useNewsletterToggler';
// const notificationRef = React.useRef<HTMLDivElement>(null);
import {
  selectNewsletter,
  setIsNewsletterOpen,
} from '../../store/newsLetter/newsLetterSlice';
type Props = {
  drawer: boolean;
};

const Navbar = (props: Props) => {
  const [isCopied, setIsCopied] = React.useState(false);
  const { writeClipboardText } = useCopyTextHook();
  const handleCopy = () => {
    writeClipboardText(user?.id || '');
    toast.success('ID Copied!');
    setTimeout(() => {
      setIsCopied(false);
    }, 5000);
  };
  const dispatch = useDispatch();
  const location = useLocation();
  const size = useWindowSize();
  const {
    homnifiNotificationList,
    unReadNotificationCount,
    isNotificationOpen,
  } = useSelector(selectNotification);
  const { newsletterList, unReadNewsletterCount, isNewsletterOpen } =
    useSelector(selectNewsletter);

  const { user } = useUserCheck();

  const { notificationPopupToggler } = useNotificationToggler();
  const { newsletterPopupToggler } = useNewsletterToggler();

  const { getHomnifiNotification, getHomnifiAllNotification } =
    useNotificationsActions();
  const { getNewsletter, getAllNewsletter } = useNewsletterActions();

  const [profile, setProfile] = React.useState(false);
  const notificationRef = React.useRef<HTMLDivElement>(null);
  const newsletterRef = React.useRef<HTMLDivElement>(null);
  const profileRef = React.useRef<HTMLDivElement>(null);
  const [businessCard, setBusinessCard] = React.useState(false);

  const hanedleToggleDrawer = () => {
    dispatch(toggleDrawer(!props.drawer));
    localStorage.setItem('drawer', JSON.stringify(!props.drawer));
  };

  React.useEffect(() => {
    if (homnifiNotificationList.length === 0) {
      getHomnifiNotification(1);
      // getHomnifiAllNotification();
    }
  }, [isNotificationOpen]);

  React.useEffect(() => {
    if (newsletterList.length === 0) {
      getNewsletter(1);
      // getAllNewsletter();
    }
  }, [isNewsletterOpen]);

  React.useEffect(() => {
    if (isNotificationOpen || location.pathname === '/notification') {
      if (size.width > 0) {
        notificationPopupToggler();
      }
    }
  }, [size]);

  useSocketEvent<DepositTransactionResponse>({
    eventName: `${user?.id}`,
    onData: (data) => {
      if (
        data.body.eventAction === TransactionEvents.DEPOSIT_TRANSACTION_SUCCESS
      ) {
        getHomnifiNotification();
        dispatch(setNewNotification(data.body));
      }
    },
  });

  useSocketEvent<DepositTransactionResponse>({
    eventName: `${user?.id}`,
    onData: (data) => {
      if (
        data.body.eventAction === TransactionEvents.DEPOSIT_TRANSACTION_FAILURE
      ) {
        getHomnifiNotification();
        dispatch(setNewNotification(data.body));
      }
    },
  });
  useSocketEvent<NewNotificationType>({
    eventName: `${user?.id}`,
    onData: (data) => {
      if (data.body.eventAction === TransactionEvents.NEW_NOTIFICATION_EVENT) {
        getHomnifiNotification();
        dispatch(setNewNotification(data.body));
      }
    },
  });

  const { userLogout } = useUserActions();
  const { userActiveStatus } = useSelector(selectSuperNodeSlice);
  const { signOutFromAllDevice } = useUserActions();
  const [isAllDevicesLogout, setIsAllDevicesLogout] = useState(false);
  const [isLogoutClicked, setIsLogoutClicked] = useState(false);

  const logoutHanlder = async () => {
    if (isAllDevicesLogout) {
      const response = await signOutFromAllDevice(
        getAccessToken() || '',
        'logout'
      );

      if (response?.data?.status) {
        dispatch(
          setUserSlice({
            isProfileCompletePercentage: false,
          })
        );
      } else {
        toast.error(response?.data?.message || 'Something went wrong');
      }
    } else {
      userLogout();
      dispatch(
        setUserSlice({
          isProfileCompletePercentage: false,
        })
      );
    }
  };
  return (
    <header className="flex flex-wrap sm:justify-start sm:flex-nowrap z-50 w-full bg-white border-b border-gray-200 text-sm h-[80px] sticky top-0">
      <nav className="relative w-full mx-auto flex items-center justify-between px-6 lg:px-12 h-full">
        <div className="flex items-center justify-between gap-6 lg:gap-12 w-full">
          <div className="flex items-center gap-6 lg:gap-12">
            <Link
              className="flex-none text-xl font-semibold"
              to="https://homnifi.com/"
              aria-label="Brand"
              target="_blank"
            >
              <Logo className="h-6 lg:h-10" />
            </Link>
            <div className="hidden lg:block">
              <button
                type="button"
                className="size-9 flex justify-center items-center text-sm font-semibold rounded-lg text-gray-800 hover:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none "
                onClick={hanedleToggleDrawer}
              >
                <Hamburger />
              </button>
            </div>
            <div className="relative items-center group z-[999] lg:flex hidden">
              <GlobalAutoComplete />
            </div>
          </div>
          <div className="flex gap-2 lg:hidden">
            <button
              type="button"
              className="size-9 flex justify-center items-center text-sm font-semibold border border-borderLightGray rounded-full text-gray-800 hover:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none md:hidden"
              onClick={hanedleToggleDrawer}
            >
              <Hamburger />
            </button>
          </div>
        </div>

        <div className="hs-collapse hidden transition-all duration-300 basis-full grow md:block h-full flex-1 w-full">
          <div className="flex flex-col gap-2 sm:flex-row sm:items-center sm:justify-end h-full w-full px-2">
            <div className="relative" ref={newsletterRef}>
              <OutsideClickHandler
                onOutsideClick={() => {
                  dispatch(setIsNewsletterOpen(false));
                }}
              >
                <Link
                  className="font-medium text-blue-600"
                  to="#"
                  aria-current="page"
                  onClick={() => {
                    dispatch(setIsNewsletterOpen(!isNewsletterOpen));
                  }}
                >
                  <GlobalIconWrap
                    notificationCount={unReadNewsletterCount}
                    active={isNewsletterOpen}
                  >
                    <NewsLetterIcon className="w-4 h-4" />
                  </GlobalIconWrap>
                </Link>
                <AnimatePresence>
                  {isNewsletterOpen && (
                    <motion.div
                      initial={{ opacity: 0, y: -50 }}
                      animate={{ opacity: 1, y: 0 }}
                      exit={{ opacity: 0, y: -50 }}
                      transition={{ duration: 0.2 }}
                      className="relative"
                    >
                      <NewsletterPopOver />
                    </motion.div>
                  )}
                </AnimatePresence>
              </OutsideClickHandler>
            </div>
            <div className="relative" ref={notificationRef}>
              <OutsideClickHandler
                onOutsideClick={() => {
                  dispatch(setIsNotificationOpen(false));
                }}
              >
                <Link
                  className="font-medium text-blue-600"
                  to="#"
                  aria-current="page"
                  onClick={() => {
                    dispatch(setIsNotificationOpen(!isNotificationOpen));
                  }}
                >
                  <GlobalIconWrap
                    notificationCount={unReadNotificationCount}
                    active={isNotificationOpen}
                  >
                    <NotificationIcon className="w-4 h-4" />
                  </GlobalIconWrap>
                </Link>
                <AnimatePresence>
                  {isNotificationOpen && (
                    <motion.div
                      initial={{ opacity: 0, y: -50 }}
                      animate={{ opacity: 1, y: 0 }}
                      exit={{ opacity: 0, y: -50 }}
                      transition={{ duration: 0.2 }}
                      className="relative"
                    >
                      <NotificationsPopOver />
                    </motion.div>
                  )}
                </AnimatePresence>
              </OutsideClickHandler>
            </div>
            <div className="flex align-middle items-center">
              <div className="relative" ref={profileRef}>
                <Link
                  className="font-medium text-blue-600"
                  to="#"
                  aria-current="page"
                  onClick={() => setProfile(!profile)}
                >
                  <GlobalIconWrap
                    active={location.pathname === '/profile'}
                    status={userActiveStatus.isActive}
                  >
                    <UserIcon className="w-4 h-4" />
                  </GlobalIconWrap>
                </Link>
                <OutsideClickHandler
                  onOutsideClick={() => {
                    setProfile(false);
                  }}
                >
                  <AnimatePresence>
                    {profile && (
                      <motion.div
                        initial={{ opacity: 0, y: -50 }}
                        animate={{ opacity: 1, y: 0 }}
                        exit={{ opacity: 0, y: -50 }}
                        transition={{ duration: 0.2 }}
                        className="relative"
                      >
                        <ProfilePopOver
                          setProfile={setProfile}
                          setBusinessCard={setBusinessCard}
                          setIsLogoutClicked={setIsLogoutClicked}
                        />
                      </motion.div>
                    )}
                  </AnimatePresence>
                </OutsideClickHandler>
              </div>
              <div className=" flex justify-start gap-2 items-start p-2">
                <div className="flex flex-col gap-2 w-full">
                  <div className="flex flex-col gap-0">
                    <div className="text-xs flex items-center gap-2">
                      ID: {user?.id && user?.id}
                      <img
                        src={isCopied ? tick : copy}
                        alt="icon"
                        className="cursor-pointer w-2.5"
                        onClick={handleCopy}
                      />
                    </div>
                    <div className="text-sm font-medium leading-0 flex items-center gap-2">
                      @{user?.username}
                      {user?.kycApproved && <VerifiedIcon />}
                    </div>
                    <div className="text-xs flex items-center gap-2">
                      {user?.email}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <ProfileQR
              businessCard={businessCard}
              setBusinessCard={setBusinessCard}
            />

            <GlobalModal
              size="sm"
              isOpen={isLogoutClicked}
              onClose={() => setIsLogoutClicked(false)}
              hideCloseButton={true}
              noHeader={true}
            >
              <div className="flex flex-col gap-8 font-light text-sm text-primaryGrey p-4">
                <div className=" font-medium text-sm text-defaultBlack">
                  Are you sure to Logout?
                </div>
                <div className="flex items-center gap-3">
                  <span className="text-black">Logout from all devices</span>
                  <CustomSwitch
                    checked={isAllDevicesLogout}
                    onChange={(checked) => setIsAllDevicesLogout(checked)}
                  />
                </div>
                <div className="flex items-center gap-4">
                  <GlobalButton
                    text="Logout"
                    type="button"
                    onClick={() => {
                      logoutHanlder();
                    }}
                    classNames="!text-sm"
                  />
                  <GlobalButton
                    outlined
                    text="Close"
                    type="button"
                    onClick={() => setIsLogoutClicked(false)}
                    classNames="!text-sm"
                  />
                </div>
              </div>
            </GlobalModal>
            <button
              type="button"
              className="size-9 flex justify-center items-center text-sm font-semibold border border-borderLightGray rounded-full text-gray-800 hover:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none lg:hidden"
              onClick={hanedleToggleDrawer}
            >
              <Hamburger />
            </button>
          </div>
        </div>
        <Notifications />
      </nav>
    </header>
  );
};

export default Navbar;
