export const API_INSTANCE_HOMNIFI = process.env.REACT_APP_API_INSTANCE_HOMNIFI;
export const API_INSTANCE_HOMNIFI_TEST = process.env.REACT_APP_TEST;
export const API_INSTANCE_BID = process.env.REACT_APP_API_INSTANCE_BID;
export const WEBSITE_DOMAIN = process.env.REACT_APP_WEBSITE_DOMAIN;
export const WALLEK_SERVICE_URI = process.env.REACT_APP_WALLEK_SERVICE_URI;
export const WALLEK_API_KEY = process.env.REACT_APP_WALLEK_API_KEY;
//Auth
export const LOGIN = '/auth/signin';
export const REGISTER = '/auth/signup';

export const ADD_REFERRAL = '/auth/set-referral-code';
export const GET_REF_ID = '/auth/get-ref';
export const GET_PROFILE = '/auth/user/';
export const FORGET_PASSWORD_REQUEST = '/auth/forgot-password';
export const FORGET_PASSWORD = '/auth/reset-password';
export const PASSWORD_CHANGE = '/auth/password/change/confirm/';

//Update Profile
export const UPDATE_PROFILE = '/profile/personal-info';
export const UPDATE_PROFILE_PICTURE = '/profile/picture/';
export const SEND_OTP = '/auth/send-otp';
export const CHANGE_EMAIL = '/profile/email/change/';
export const CONFIRM_EMAIL = '/profile/email/change/confirm/';
export const VERIFY_EMAIL = '/auth/verify-email';
export const CHANGE_PASSWORD = '/profile/change-password';
export const USER_INFO = '/profile';
export const CHECK_EMAIL = '/auth/check-email';
export const CHECK_REF_ID = '/auth/check-referral-code';
export const CHECK_USERNAME = '/auth/check-username';
export const FETCH_LATEST_USER_DATA = '/profile/details';
export const VIEW_SECURITY_CODE = '/profile/view-security';
export const GENERATE_GOOGLE_2FA = '/profile/qr-code';
export const VERIFY_GOOGLE2FA = '/profile/toggle-2fa';
export const SET_SECURITY_CODE = '/profile/security-code';
export const NOTIFICATIONS_SETTINGS = '/profile/notification-preference';

export const RECENT_DEVICES = '/device';
export const GET_ACIVE_MEMBERSHIP = '/profile/stats/active-membership/';
export const GET_ACIVE_MEMBERSHIP_UPLINE = '/profile/upline/active-membership/';

//KYC
export const CHECK_KYC = `kyc/check/`;
export const POST_VERIFY = '/profile/user-verification';
export const START_KYC = '/kyc/start/';
//Wallet
export const WALLET_OVERVIEW = '/wallet/overview';
export const WALLET_ASSETS = '/wallet/assets';
export const WALLET_GROWTH = '/wallet/growth';
export const WITHDRAW_HISTORY = '/wallet/withdraw/history';
export const TRANSACTION_HISTORY = '/wallet/history';
export const MIGRATION_HISTORY = '/wallet/migrate/history';
export const ALL_TOKENS = '/wallet/tokens';
export const ALL_NETWORKS = '/wallet/networks';
export const POST_WITHDRAW = '/wallet/withdraw';
export const POST_SWAP = '/wallet/swap';

export const WITHDRAW_ADDRESS = '/wallet/withdraw/addresses';
export const WITHDRAW_PAYMENT_LINK = '/feature/purchase';
export const WALLET_TOKEN_PAIR = '/wallet/swap/ratio';
export const WALLET_MIGRATE = '/wallet/migrate';
export const WALLET_DEPOSIT = '/wallet/topup';
export const WALLET_TOPUP_HISTORY = '/wallet/topup/history';
;
//Shop
export const SHOP_PROVIDERS = '/shop/providers';
export const SHOP_METHODS = '/shop/payment-methods';
export const GET_PRODUCTS = '/shop/products';
export const BUY_PRODUCT = '/shop/buy';
export const GET_PURCHASES = '/shop/purchases';
export const GET_ORDER_DATA = '/shop/order';

export const VERIFYING_EMAIL = '/auth/verify-email';
export const STEPPER = '/profile/onboarding-steps';
export const STEPPER_ACCOUNT = '/user/set-ktm';

//Notifications
export const GET_USER_ACTIVITY = '/profile/activity';
// export const READ_NOTIFICATIONS = "/profile/activity";
export const READ_NOTIFICATIONS = '/notifications';
export const DELETE_NOTIFICATIONS = '/notifications/delete/';
export const READ_ALL_NOTIFICATIONS = '/notifications/mark-all-read';
export const NOTIFICATIONS_BADGE = '/notifications/badge';

export const REFERRAL_ACTIVITY_HISTORY = '/user/members-activity';
export const REFERRAL_ANALYTICS = '/user/community/ref-analytics';

export const TOOLS_BASE_SERVER = 'https://tools.blpro8.club';
export const CHECK_USER_API: string = TOOLS_BASE_SERVER + '/check-broker-user';
export const ACTIVE_STRATEGY = TOOLS_BASE_SERVER + '/active-strategy';
export const BROKER_ACCOUNT_REGISTRATION: string =
  TOOLS_BASE_SERVER + '/register-broker';

// Cloudk2 //
export const USER_REWARDS = '/cloud-k/rewards';
export const USER_MACHINES = '/cloud-k/machines';
export const USER_MACHINES_BY_ID = '/cloud-k/machines/details';
export const USER_GLOBAL_AUTO_COMPOUND = '/cloud-k/auto-compound';
export const USER_PRODUCTS = '/cloud-k/products';
export const USER_GLOBAL_MACHINE_AUTO_COMPOUND =
  '/cloud-k/machines/auto-compound';
export const USER_GLOBAL_CLAIM_REWARDS = '/cloud-k/claim';
export const USER_GLOBAL_USER_MACHINE_REWARDS = '/cloud-k/machines/rewards';
export const CLOUDK_TRANSACTIONS = '/cloud-k/transactions';

// My Friends
export const FRIENDS_ALL_HISTORY = '/my-friends/bonus/history';
export const FRIENDS_TOTAL_REWARDS = '/my-friends/total-rewards';

export const REWARDS_CHART = '/my-friends/rewards/chart';

//Super Node
export const SUPER_NODE_PRODUCTION = '/supernode/production';
export const SUPERNODE_REWARD_ANALYTICS = '/supernode/reward-analytics'; 
export const DAILY_SUPER_NODE_REWARDS = '/supernode/reward-analytics'
export const TOTAL_LOSS = '/supernode/reward/loss';
export const SUPER_NODE_DAILY_REWARD = '/supernode/daily/reward';
export const SUPER_NODE_FIRSTLINE_USERS = '/supernode/firstline-users';
export const SUPER_NODE_CLAIM_REWARDS = '/supernode/claim';
export const SUPER_NODE_MY_TREE = '/supernode/childrens';
export const SUPER_NODE_MY_TREE_REWARDS = '/supernode/tree';
export const SUPER_NODE_SNGP_REWARDS = '/supernode/global-pool';
export const USER_DAILY_REWARDS = '/supernode/community/users';
export const USER_Chart_Detail = '/supernode/community/production-graph';
export const USER_REWARD_HISTORY = '/supernode/community/rewards';
export const SUPER_NODE_SUPER_NODE_REWARDS = '/supernode/rewards/list'
export const SUPER_NODE_ACTIIVE_USER = '/supernode/is-active';



export const USER_STAKE_INTO_MACHINE = '/cloud-k/machines/stake';

// SUPPORT
export const SUPPORT_PLATFORMS = '/support';

// Utils
export const GET_COUNTRIES = '/utils/countries';
export const GET_STORES_URL = '/setting/link';

// Legal Content
export const LEGAL_CONTENT = '/legal-content';

// Feature
export const FEATURE = '/feature';

//MAINTENANCE
export const MAINTENANCE = '/maintenance';

// Referral
export const REFERRAL_HISTORY = '/referral/history';
export const REFERRAL_USER_REWARDS = '/referral/rewards';
export const REFERRAL_CHART_ANALYTICS = '/referral/users/chart';

// Profile
export const PROFILE_STEPS = '/profile/onboarding-steps';

export const SWAP_HISTORY = '/wallet/swap/history';
export const WALLET_SWAP_PRIORITY = '/wallet/swap/priority';

// Dashboard
export const DASHBOARD_SHOP_PURCHASED = '/shop/purchased';
export const RETRY_USER_VERIFICATION = '/profile/retry-user-verification';

// Setting
export const LINK_LIST = '/setting/link';
export const LINK_WIKI = '/utils/wiki';
export const SHOW_PAGE = '/setting/showpage';
export const PLATFORM_SETTINGS = '/platform-settings';

// IMPERSONATE
export const IMPERSONATE_LOGIN = '/auth/impersonate';

// Platform

export const PLATFORM_LIST = '/platforms/';
export const FEATURED_PLATFORM_LIST = '/platform/featured';
export const FAVORITE_PLATFORM_LIST = '/platform/favorite';

export const GET_ADS = '/platform/ads';

// BID PATH

// Login CONFIRM
export const LOGIN_CONFIRM = '/auth/login/confirm/';
export const RESEND_OTP = '/auth/login/resend-otp/';
export const ACCOUNT_TYPE = '/auth/account-type/';
export const ACCEPT_AGREEMENT = '/profile/accept-terms-and-conditions/';
export const GET_TOTP = '/otp/settings/totp/';
export const GET_MEMBERSHIP_STATUS = '/user/membership/';
export const GET_USER = '/auth/user/';
export const FORGOT_PASSWORD = '/auth/password/reset/';
export const SUBMIT_REFERRAL = '/auth/referral/submit/';
export const RESET_PASSWORD = '/auth/password/reset/confirm/';
export const SIGNOUT_USER = '/auth/signout/';
export const SIGNOUT_FROM_ALL_DEVICE = '/auth/signout-from-all-devices';

export const CHNAGE_PASSWORD = '/auth/password/change/';

// SUPER NODE

export const TOTAL_GASK = '/supernode/gask/balance';

// GENERAL
export const COUNTRIES = '/auth/countries/';

// ADDRESS
export const UPDATE_ADDRESS = '/profile/residential-details/';
export const UPDATE_NAME = '/profile/name/update/';

//WALLET DATA
/**/
export const WALLET_DATA_WALLETS_LIST = '/wallets/balance';
export const WALLET_DATA_NETWORKS_LIST = '/tokens/networks';
/**/
export const WALLET_DATA_WITHDRAW_HISTORY_GET = '/wallets/withdraws';
export const WALLET_DATA_TRANSFER_HISTORY_GET = '/wallets/transfers';
export const WALLET_DATA_WITHDRAW_AND_TRANSFER_HISTORY_GET =
  '/wallets/withdraws-and-transfers';
export const WALLET_DATA_SWAP_REQUEST_GET = '/wallets/swaps';
export const WALLET_DATA_DEPOSIT_HISTORY_GET = '/wallets/deposits';
/**/
export const WALLET_DATA_WITHDRAW_HISTORY_POST = '/wallets/withdraws/request';
export const WALLET_DATA_TRANSFER_HISTORY_POST = '/wallets/transfers/request';
export const WALLET_DATA_SWAP_REQUEST_POST = '/wallets/swaps';
export const WALLET_DATA_DEPOSIT_HISTORY_POST = '/wallets/deposits';
/**/
export const WALLET_DATA_TRX_SETTING = '/trx-settings';
export const WALLET_DATA_ALL_TOKENS = '/tokens/all';
/**/
export const WALLET_DATA_FAQS = '/faq/modules';
export const GET_SUPPORT = '/support';
/**/
export const GET_DEPOSIT_ADDRESS = '/wallets/deposit/address';
/**/
export const WALLET_DATA_SETTINGS = '/wallets';
/**/
export const VALIDATE_ADDRESS = '/wallets/withdraws/validate';
export const VALIDATE_USER = '/tp/users';

//HOMNIFI NOTIFICATIONS
export const CREATE_HOMNIFI_NOTIFICATIONS = '/notification-module';
export const GET_HOMNIFI_NOTIFICATIONS = '/notification-module';
export const READ_HOMNIFI_NOTIFICATION = '/notification-module/read';
export const ALL_READ_HOMNIFI_NOTIFICATIONS = '/notification-module/all-read';

export const UPDATE_USER_DATA = '/user';
export const CHECK_MEMBERSHIP = '/partner/users/';



// NEWS LETTER 
export const CREATE_NEWS_LETTER = '/news/user';
export const GET_NEWS_LETTER = '/news/user';
export const READ_NEWS_LETTER = '/news/read';
export const READ_ALL_NEWS_LETTER = '/news/all-read';
