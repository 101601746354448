import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  SocketIOInitialState,
  SocketIOSConnectionStatus,
} from '../../types/socketIO.types';
import { Socket } from 'socket.io-client';

const initialState: SocketIOInitialState = {
  socket: null,
  disconnectReason: undefined,
  error: '',
  isError: false,
  isConnected: false,
  isDisconnected: false,
  isConnecting: true,
  status: SocketIOSConnectionStatus.CONNECTING,
};

export const SOCKETIO_SLICE_NAME = 'socketIO';

const slice = createSlice({
  name: 'socketIO',
  initialState,
  reducers: {
    setSocket: (state, action: PayloadAction<Socket>) => {
      return { ...state, socket: action.payload };
    },
    setIsConnected: (state, action: PayloadAction<boolean>) => {
      return { ...state, isConnected: action.payload };
    },
    setIsDisconnected: (state, action: PayloadAction<boolean>) => {
      return { ...state, isDisconnected: action.payload };
    },
    setIsConnecting: (state, action: PayloadAction<boolean>) => {
      return { ...state, isConnecting: action.payload };
    },
    setConnectionStatus: (
      state,
      action: PayloadAction<SocketIOSConnectionStatus>
    ) => {
      return { ...state, status: action.payload };
    },
    setError: (state, action: PayloadAction<string>) => {
      return { ...state, error: action.payload };
    },
    setDisconnectReason: (state, action: PayloadAction<string | undefined>) => {
      return { ...state, disconnectReason: action.payload };
    },
  },
});

export const {
  setSocket,
  setError,
  setIsConnected,
  setIsDisconnected,
  setIsConnecting,
  setConnectionStatus,
  setDisconnectReason,
} = slice.actions;
export const selectSocketIO = (state: { socketIO: SocketIOInitialState }) =>
  state.socketIO;
export default slice.reducer;
