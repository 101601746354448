import axios, { AxiosError, AxiosResponse } from 'axios';
import { API_INSTANCE_BID } from './ApiEndpoints';
import { clearStorage, getLoginToken } from '../storage/protectedStorage';
import { ACCESS_TOKEN, LOGIN_TOKEN } from '../constants';
import { PAGES } from '../routers';
import currentStorage from '../currentStorage';
import { store } from '../../store';
import { reset } from '../../store/user/userSlice';
import { showModal } from '../../store/modal/modalSlice';


export const logout = () => {
  store.dispatch(reset());
  const storage = currentStorage();
  storage.removeItem(LOGIN_TOKEN);
  storage.removeItem(ACCESS_TOKEN);
  localStorage.removeItem('valuesOfRememberMe');
  clearStorage();
  localStorage.clear();
  sessionStorage.clear();
  window.location.href = PAGES.LOGIN;
};

export default function ApiRequestBID() {
  const loginToken = getLoginToken();

  const request = axios.create({
    baseURL: `${API_INSTANCE_BID}`,
    headers: {
      Authorization: `Token ${loginToken || null}`,
    },
    responseType: 'json',
    socketPath: null,
  });

  request.interceptors.response.use(
    (response: AxiosResponse): AxiosResponse => response,
    (error: AxiosError) => {
      console.log(error)
      const statusCode = error.response?.status;

      if (error.code === 'ERR_NETWORK') {
        return error;
      }
console.log('statusCode ' , statusCode)
      if (statusCode === 401) {
        console.log('inside 401');

        if (window.location.pathname !== '/auth/reset-password') {
          console.log('inside location showing modal');
          store.dispatch(showModal());
        }
        return error;
      }

      if (error.status === 403) {
        store.dispatch(showModal());
        return error;
      }

      return Promise.reject(error.response);
    }
  );

  return request;
}