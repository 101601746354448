import { useDispatch, useSelector } from 'react-redux';
import { useWindowSize } from '../utils/helper';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  setIsNewsletterOpen,
} from '../store/newsLetter/newsLetterSlice';
import { selectNewsletter } from '../store/newsLetter/newsLetterSlice';

function useNewsletterToggler() {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const size = useWindowSize();

  const { isNewsletterOpen } = useSelector(selectNewsletter);

  const newsletterPopupToggler = () => {
    if (isNewsletterOpen || location.pathname === '/newsletter') {
      if (size.width > 0) {
        if (size.width <= 768 && isNewsletterOpen) {
          dispatch(setIsNewsletterOpen(false));
          navigate('/newsletter');
        } else {
          if (location.pathname === '/newsletter' && size.width >= 768) {
            dispatch(setIsNewsletterOpen(true));
            navigate('/');
          }
        }
      }
    }
  };

  return {
    newsletterPopupToggler,
  };
}

export default useNewsletterToggler;
