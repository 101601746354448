import { ReactComponent as Spinner } from '../../SVGIcons/spinner.svg';
const PreLoader = () => {
  return (
    <div className="w-full h-full flex items-center justify-center">
      <Spinner className="animate-spin h-16 w-16 text-primary" />
    </div>
  );
};

export default PreLoader;
