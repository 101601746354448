import React from 'react';
import {
  KYCIcon,
  NotificationIconGray,
  ProfileIcon,
  RecentIcon,
  SecurityIcon,
} from '../../images/svgs';
import { useDispatch, useSelector } from 'react-redux';
import { selectUserSlice, setUserState } from '../../store/user/userSlice';
import useUserCheck from '../../hooks/useUserCheck';

// export type TabId = 'profile' | 'kyc' | 'security' | 'notification' | 'recentDevices';

export const TABS_KEYS = {
  PROFILE: 'PROFILE',
  KYC: 'KYC',
  SECURITY: 'SECURITY',
  NOTIFICATION: 'NOTIFICATION',
  RECENT_DEVICES: 'RECENT_DEVICES',
};

const Tabs: React.FC<any> = () => {
  const { user } = useUserCheck();

  const _tabs = [
    {
      id: TABS_KEYS.PROFILE,
      label: 'Profile',
      icon: <ProfileIcon className={`h-4  `} />,
    },
    {
      id: TABS_KEYS.KYC,
      label: user?.accountType === 'PERSONAL' ? 'KYC' : 'KYB',
      icon: <KYCIcon className="h-4" />,
    },
    {
      id: TABS_KEYS.SECURITY,
      label: 'Security',
      icon: <SecurityIcon className="h-4" />,
    },
    {
      id: TABS_KEYS.NOTIFICATION,
      label: 'Notification Settings',
      icon: <NotificationIconGray className="h-4 " />,
    },
    {
      id: TABS_KEYS.RECENT_DEVICES,
      label: 'Recent Devices',
      icon: <RecentIcon className="h-4" />,
    },
  ];

  const { profileActiveTab } = useSelector(selectUserSlice);
  const dispatch = useDispatch();

  return (
    <div>
      <div>
        {_tabs.map((tab) => (
          <button
            key={tab.id}
            type="button"
            className={`${
              profileActiveTab === tab.id
                ? ' border-primary font-medium text-primary bg-primary bg-primary/15'
                : 'border-borderLightGray text-black'
            } m-2 bg-white py-2 px-3 rounded-md inline-flex items-center gap-x-2 border text-sm whitespace-nowrap `}
            onClick={() => dispatch(setUserState({ profileActiveTab: tab.id }))}
          >
            {tab.icon}
            {tab.label}
          </button>
        ))}
      </div>
    </div>
  );
};

export default Tabs;
