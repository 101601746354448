import { lazy } from 'react';

/* App Pages */
export const Login = lazy(() => import('./Auth/Login'));
export const Signup = lazy(() => import('./Auth/Signup'));
export const VerifyEmail = lazy(() => import('./Auth/VerifyEmail'));
export const SSOAuth = lazy(() => import('./Auth/SSOAuth/SSOAuth'));
export const EmailSent = lazy(() => import('./Auth/EmailSent'));
export const Terms = lazy(() => import('./Auth/Terms'));
export const Success = lazy(() => import('./Auth/Success'));
export const ReferralLink = lazy(() => import('./Auth/Referral'));
export const GoogleAuth = lazy(() => import('./Auth/2FA'));
export const ResetPassword = lazy(() => import('./Auth/ResetPassword'));
export const ForgetPassword = lazy(() => import('./Auth/ForgetPassword'));
export const AccountType = lazy(() => import('./Auth/AccountType'));
export const Maintenance = lazy(() => import('./Maintenance'));
export const Profile = lazy(() => import('./Profile'));
export const WalletsWithdraw = lazy(() => import('./Wallet/WalletsWithdraw'));
export const WalletsSwap = lazy(() => import('./Wallet/WalletSwap'));
export const WalletsHistory = lazy(() => import('./Wallet/WalletsHistory'));
export const WalletsDeposit = lazy(() => import('./Wallet/WalletsDeposit'));
export const ExternalApp = lazy(() => import('./ExternalApp'));
export const Dashboard = lazy(() => import('./Dashboard'));
export const Wallet = lazy(() => import('./Wallet'));
export const Cloud2 = lazy(() => import('./Cloud2'));
export const Walletk = lazy(() => import('./Walletk'));
export const Xera = lazy(() => import('./Xera'));
export const Wiki = lazy(() => import('./Wiki'));
export const Legal = lazy(() => import('./Legal'));
export const MyFriendsPage = lazy(() => import('./MyFriends'));
export const SuperNode = lazy(() => import('./SuperNode'));
export const Support = lazy(() => import('./Support'));
export const PageNotFound = lazy(() => import('./PageNotFound'));
export const Notifiations = lazy(() => import('./Notification'));
export const Newsletter = lazy(() => import('./Newsletter'));
export const Defi = lazy(() => import('./Defi'));
export const Cloudk2PurchasePage = lazy(() => import('./Cloud2/purchase'));
