//REDUX-TOOLKIT
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { INewsletterInitialState } from '../../types/newsletter.types';
const MAX_NOTIFICATIONS = 5;

const initialState: INewsletterInitialState = {
  list: [],
  badge: 0,
  userActivity: {
    list: [],
    paginate: null,
    pageLoaded: 0,
  },
  badgeUpdated: false,
  newsletterData: {
    totalCount: 0,
    list: [],
    totalPages: 0,
    currentPage: 0,
  },
  newsletterList: [],
  allNewsletterList: [],
  newNewsletterList: [],
  isLoading: false,
  unReadNewsletterCount: 0,
  isNewsletterModalId: '',
  isNewsletterOpen: false,
  isNewsletterModal: false,
};

export const NEWS_LETTER_SLICE_NAME = 'newsletter';
const newsletterSlice = createSlice({
  name: NEWS_LETTER_SLICE_NAME,
  initialState,
  reducers: {
    setNewsletterModal: (state, action: PayloadAction<any>) => {
      return { ...state, isNewsletterModal: action.payload };
    },
    setNewsletterModalId: (state, action: PayloadAction<any>) => {
      return { ...state, isNewsletterModalId: action.payload };
    },
    setUserActivity: (state, action: PayloadAction<any>) => {
      if (action.payload.force) {
        return {
          ...state,
          userActivity: {
            list: action.payload.activityData.list,
            paginate: action.payload.activityData.paginate,
            pageLoaded: action.payload.page,
          },
          badge: action.payload.activityData.badgeCount,
        };
      }
      if (action.payload.page > state.userActivity.pageLoaded)
        return {
          ...state,
          userActivity: {
            list: [
              ...state.userActivity.list,
              ...action.payload.activityData.list,
            ],
            paginate: action.payload.activityData.paginate,
            pageLoaded: action.payload.page,
          },
          badge:
            action.payload.page === 1
              ? action.payload.activityData.badgeCount
              : 0,
        };
    },
    setNewsletterData: (state, action: PayloadAction<any>) => {
      return { ...state, list: action.payload };
    },
    setBadge: (state, action: PayloadAction<any>) => {
      return { ...state, badge: action.payload };
    },
    setBadgeUpdated: (state) => {
      return { ...state, badge: 0, badgeUpdated: true };
    },
    setIsNewsletterOpen: (state, action: PayloadAction<any>) => {
      return { ...state, isNewsletterOpen: action.payload };
    },
    removeNewsletter: (state, action: PayloadAction<any>) => {
      const newArray: any = state.list.filter(
        (item: any) => item.id !== action.payload
      );

      return { ...state, list: newArray };
    },

    setNewsletterList: (state, action: PayloadAction<any>) => {
      const list =
      action.payload.currentPage <= state.newsletterData.currentPage
        ? action.payload.list
        : [...state.newsletterList, ...action.payload.list];

    const result = list.filter((itm: any) => !itm.read);
    return {
      ...state,
      newsletterList: list,
      newsletterData: action.payload,
      unReadNewsletterCount: result.length,
    };
    },
    setAllNewsletterList: (state, action: PayloadAction<any>) => {
      return {
        ...state,
        allNewsletterList: action.payload,
      };
    },

    setIsLoading: (state, action: PayloadAction<any>) => {
      return { ...state, isLoading: action.payload };
    },

    updateNewsletterStatus: (state, action: PayloadAction<any>) => {
      const result = state.newsletterList.map((itm: any) => {
        if (itm._id === action.payload) {
          return {
            ...itm,
            read: true,
          };
        }
        return itm;
      });
      return {
        ...state,
        newsletterList: result,
        unReadNewsletterCount:
          state.unReadNewsletterCount <= 0
            ? 0
            : state.unReadNewsletterCount - 1,
      };
    },

    setMarkAllAsRead: (state, action: PayloadAction<any>) => {
      const result = state.newsletterList.map((itm: any) => {
        return {
          ...itm,
          read: true,
        };
      });

      return {
        ...state,
        newsletterList: result,
        unReadNewsletterCount: 0,
      };
    },

    setNewNewsletter: (state, action) => {
      if (state.newNewsletterList.length >= MAX_NOTIFICATIONS) {
        state.newNewsletterList.shift();
      }
      state.newNewsletterList.push(action.payload);
    },
    removeNewNewsletter: (state) => {
      state.newNewsletterList.shift();
    },
  },
});

export const {
  setNewsletterModal,
  setNewsletterModalId,
  setNewsletterData,
  setBadge,
  removeNewsletter,
  setUserActivity,
  setBadgeUpdated,
  setNewsletterList,
  setIsLoading,
  updateNewsletterStatus,
  setMarkAllAsRead,
  setIsNewsletterOpen,
  setAllNewsletterList,
  setNewNewsletter,
  removeNewNewsletter,
} = newsletterSlice.actions;

export const selectNewsletter = (state: {
  [NEWS_LETTER_SLICE_NAME]: INewsletterInitialState;
}) => state[NEWS_LETTER_SLICE_NAME];

export const selectNewsletterModal = (state: {
  newsletter: INewsletterInitialState;
}) => state.newsletter.isNewsletterModal;
export const selectNewsletterModalId = (state: {
  newsletter: INewsletterInitialState;
}) => state.newsletter.isNewsletterModalId;
export const selectNewsletterData = (state: {
  newsletter: INewsletterInitialState;
}) => state.newsletter.list;
export const notification_badge = (state: {
  newsletter: INewsletterInitialState;
}) => state.newsletter.badge;
export const selectUserActivities = (state: {
  newsletter: INewsletterInitialState;
}) => state.newsletter.userActivity;
export const selectBadgeUpdated = (state: {
  newsletter: INewsletterInitialState;
}) => state.newsletter.badgeUpdated;

export default newsletterSlice.reducer;
